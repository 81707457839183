import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Endoscopy_Data_Images_Display from "../../components/Endoscopy/Endoscopy_Data_Images_Display";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import { Endoscopy_EntriesPerPage } from './Endoscopy_Categories_Page';

const Endoscopy_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PrepareItemPage(location, user.language, null, Endoscopy_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.item)
        return;

    return <div className="flex_container" key={pageData.item.id}>
        <div className='flex_container_title'>
            <div className='item flex_item_1'>
                {pageData.category['name_' + user.language]}
            </div>
            <TopCaseNavigation className='top_case_navigation_block' pageData={pageData} />
        </div>
        <hr />
        <br></br>
        <div className='case_flex'>
            <div className='case_conclusion'>
                <div className='case_text_info'>{t('conclusion')}</div>
                <div className='case_text_block_bold'>
                    <div className='case_icon'>
                        <img src='/icons/case_icon.svg'></img>
                    </div>
                    {pageData.item['description_' + user.language]}</div>
            </div>
        </div>
        <div className='case_description'>
            <div className='case_text_block'>
                <Endoscopy_Data_Images_Display imageurl={pageData.item.images_path} />
            </div>
        </div>
        <BottomCaseNavigation pageData={pageData} />
    </div>;
};

export default Endoscopy_Data_Entrie_Page;
