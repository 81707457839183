import React, {useContext, useState} from 'react';
import {Context} from "../../index";
import {useNavigate} from "react-router-dom";
import {TestExtra_CATEGORIES_ROUTE} from "../../utils/consts";
import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button'
const TestExtra_Categories_ButtonDisplay =  ({i}) => {
    const {record} = useContext(Context)
    const {user} = useContext(Context)
    const history = useNavigate()
    const [open, setOpen] = useState(false);

    return (
            <Button key={record.testExtra.testExtra_categories[i].id}
                style={{
                    color: 'black',
                    backgroundColor: '#f3f4f8',
                    width: '100%',
                    cursor: 'pointer'
                }}
                active={record.testExtra.testExtra_categories[i].id === record.testExtra.selectedTestExtra_Categories.id}
                onClick={() => {
                    record.testExtra.setSelectedTestExtra_Categories(record.testExtra.testExtra_categories[i]);
                    history(TestExtra_CATEGORIES_ROUTE + '/' + record.testExtra.testExtra_categories[i].id)
                }}
            >
                {record.testExtra.testExtra_categories[i]["name_"+user.language]}
            </Button>
    );
};
export default TestExtra_Categories_ButtonDisplay;