import { useContext } from "react";
import { Context } from "../index";
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchTable, MakeTree } from "../utils/PageDataPrepare";

const HasDemoBadge = () => {
    return <>
        &emsp;
        <span className='badge rounded-pill text-bg-primary fw-normal'>
            демо
        </span>
    </>
};

const BottomCategoryButton = ({ category }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { pathname } = useLocation();
    const [pimuCategory, categoryName] = pathname.substring(1).split('/');

    if (!category)
        return;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCategory = () => {
        scrollToTop();
        user.gender = null;

        //  Если у этой категории нет подкатегорий (sublevels_count == 0), то перейти на страницу выбора кейса.
        //  Если же у категории есть подкатегории, то перейти на страницу, где будут отображены стандартные подкатегории для выбранной.
        if (category.sublevels_count == 0)
            history(`${FetchTable[categoryName].route}/${category.id}?page=1&gender=3`);
        else
            history(`${category.id}?page=1&gender=3`);

        return;
    }

    return <button className='biology_card-items' onClick={TryNavigateToCategory}>
        <div className='biology_card-icon'>
            <img src={category.icons} alt={`Иконка категории ${category['name_' + user.language]}`} />
        </div>
        <div className='biology_card-name'>
            {category['name_' + user.language]}
            {user?.access_level?.demo_access && Number(category.totalDemoCases) > 0 && <HasDemoBadge />}
        </div>
    </button>;
};

const BottomCategoriesDisplay = ({ categories, matchingCategories }) => {

    if (!categories)
        return;

    let categoriesArray = categories instanceof Array ? categories : Object.keys(categories).map((key) => categories[key]);

    //  Привести объекты массива категорий к одному типу.
    categoriesArray = categoriesArray.filter((category) => typeof category == "object").map((category) => { return { ...category, sublevel: category?.sublevel === undefined ? 0 : category.sublevel }; }).sort((a, b) => a.position > b.position);

    //  Преобразовать обычный список записей в структурированный древообразный вид.
    //  Будут выделены "корневые" категории и для каждой из них будет сформирован список потомков и так до конца.
    const categoriesTree = MakeTree(categoriesArray, matchingCategories);


    return <div className='biology_card-list'>
        {categoriesTree.map((category) => <BottomCategoryButton key={category.id} category={category} />)}
    </div>
};

export default BottomCategoriesDisplay;