import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Patanatomy ]

//===========
// begin [ Patanatomy_Categories ]

export const fetchPatanatomy_Categories = async (search, language) => {
    const {data} = await $host.get('api/patanatomy/patanatomy_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOnePatanatomy_Categories = async (id) => {
    const {data} = await $host.get('api/patanatomy/patanatomy_categories/' + id)
    return data
}

// end [ Patanatomy_Categories ]
//===========

//-----------

//===========
// begin [ Patanatomy_Data ]

export const fetchPatanatomy_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/patanatomy/patanatomy_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOnePatanatomy_Data = async (id) => {
    const {data} = await $host.get('api/patanatomy/patanatomy_data/' + id)
    return data
}

// end [ Patanatomy_Data ]
//===========

//// end [ Patanatomy ]
////===========

//-----------