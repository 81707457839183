import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./Docs_Page.css"

const Contacts_Page = observer(() => {
    const history = useNavigate()

    return (
        <div className='docs_page'>
            <div className='docs_title'>Контакты</div>
            <br></br>
            <div className='docs_content'>
                <div className='docs_sidebar'>
                    <div className='docs_sidebar_option'>Техническая поддержка</div>
                    <div className='docs_sidebar_option'>Предложения</div>
                    <div className='docs_sidebar_option'>Демо-доступ</div>
                </div>
                <div className='docs_paragraph'>
                    <div className='docs_paragraph_info'>
                        В данном разделе вы можете узнать как связаться с нами
                    </div>
                    {/* <div className='docs_email_name'>Техническая поддержка приложения:</div>
                    <div className='docs_email_address'>medatlas_support@medatlas.ru</div>
                    <div className='docs_email_name'>По остальным вопросам/предложениям:</div>
                    <div className='docs_email_address'>medatlas_info@medatlas.ru</div> */}
                </div>
            </div>
        </div>
    );
});

export default Contacts_Page;