import React, { useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import "./Search_Bar.css"
import { useTranslation } from "react-i18next";

const Search_Bar = observer(({ searchSucceeded }) => {
    const { t } = useTranslation();
    const { user } = useContext(Context);
    const [searchTerm, setSearchTerm] = useState(""); // Состояние для значения поиска

    const handleChange = (e) => {
        setSearchTerm(e.target.value); // Обновляем состояние значения поиска при изменении в поле ввода
    };

    const handleClick = () => {
        user.setSearch(searchTerm); // Устанавливаем значение поиска в контекст пользователя
    };

    const handleClear = () => {
        setSearchTerm(""); // Очищаем значение поиска
        user.setSearch(""); // Обнуляем значение в контексте пользователя
    };

    const handleKeyDown = (event) => {
        if (event.key != 'Enter')
            return;

        handleClick();
    };

    return <div className='search_block'>
        <div className='search_block_title'>
            {t('search_block_title')}
        </div>
        <div className='search_block_form'>
            <img className='search_icon' src='/icons/search_icon.svg' alt='search_icon' />
            <input className={`search_block_bar ${searchSucceeded === false ? 'invalid' : ''}`}
                type="search"
                value={searchTerm} // Значение в поле ввода связано со состоянием
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={t("pathanatomy_placeholder")} />
            <button type="button" className='search_block_button' onClick={handleClick}>
                {t('search_block_button')}
            </button>
            <div className='search_tips'>{t('search_tips')}
            </div>
        </div>
    </div>

});

export default Search_Bar;