import { observer } from "mobx-react-lite";
import Genetics_Data_EntriesItem from "./Genetics_Data_EntriesItem";

const Genetics_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='dzi_items_flex'>
        {pageData.items.rows.map((item) => <Genetics_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Genetics_Data_EntriesList;