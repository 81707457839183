import React from 'react';
import { useTranslation } from "react-i18next";
import "./InProcess.css"

const InProcess = (() => {
    const { t } = useTranslation();

    return (
        <div className='in_process_block'>
            <img src='/icons/in-process.svg' alt='tool'></img>
            {t('in_process_block')}</div>
    );
});

export default InProcess;