import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { SkinDisease_CATEGORIES_ROUTE, SkinDisease_DATA_ENTRIE, DEMO_VERSION_ROUTE } from '../../utils/consts';

const SkinDisease_Data_EntriesItem = ({ item }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };


    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            history(DEMO_VERSION_ROUTE);
            return;
        }

        scrollToTop();
        history(`${SkinDisease_CATEGORIES_ROUTE}${SkinDisease_DATA_ENTRIE}/${item.id}`);
    };
    const getImagePath = (path) => {
        return path.startsWith('/') ? path : `/${path}`;
    };
    return <div className="case_preview-item1" onClick={TryNavigateToCase}>
        {item.demo_access && user.access_level.demo_access && <div className='demo_version_tif_preview'>
            {t("Demo")}
        </div>}
        <img src={getImagePath(item.images_path_preview)} alt="image preview" />
        <div className='case_preview_info1'>
            <div className='case_preview_title1'>
                {item['name_' + user.language]}
            </div>
            <div className='case_preview_latin_name1'>{item['name_two_' + user.language]}</div>
        </div>
    </div>;
};

export default SkinDisease_Data_EntriesItem;