import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import Gistology_Data_EntriesItem from "./Gistology_Data_EntriesItem";
import { fetchGender } from '../../http/Data_API/Gender_Data_API';

const Gistology_Data_EntriesList = observer(({ pageData }) => {
    const [genders, setGenders] = useState(null);

    useEffect(() => {
        fetchGender().then((genders) => setGenders(genders));
    }, [pageData]);

    if (!pageData?.items?.rows || !genders)
        return;

    return <div className='dzi_items_flex'>
        {pageData.items.rows.map((item) => <Gistology_Data_EntriesItem key={item.id} item={item} genders={genders} />)}
    </div>;
});

export default Gistology_Data_EntriesList;