import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import DZI_Images_Display from "../../components/DZI_OpenSeadragon/DZI_Images_Display";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import QuizComponent from '../../components/Quiz';

const Biology_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PrepareItemPage(location, user.language).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.item)
        return;

    return <>
        <div id='example-destination' name='example-destination' className='biology_title'>
            <img className='page_title_icon' src='/icons/parazitology_icon.png' alt='page_title_icon'></img>{t('Bio_title')}
        </div>
        <div className="flex_container" key={pageData.item.id}>
            <div className='flex_container_title'>
                <div className='item flex_item_1'>
                    {pageData.category['name_' + user.language]}
                </div>

                <div className='top_case_navigation_block'>
                    <TopCaseNavigation pageData={pageData} />
                </div>
            </div>
            <hr />

            <div className='case_birth'>
                <div className='case_text_info' key={pageData.item.id}></div>
                <div className='case_bold_name'>{pageData.item['name_' + user.language]}</div>
                <div className='case_preview_latin_name1'>
                    {pageData.item['name_two']}
                </div>
            </div>
            <div>
                <div className='case_text_block'>{pageData.item['description2_' + user.language]}</div>
            </div>
            <div className='dzi_section'>
                <DZI_Images_Display images={pageData.item.images_path_dzi} />
            </div>
            <QuizComponent testData={pageData.item?.testData} />
            <BottomCaseNavigation pageData={pageData} />
        </div>
    </>
};

export default Biology_Data_Entrie_Page;
