import { observer } from "mobx-react-lite";
import Modelling_Data_EntriesItem from "./Modelling_Data_EntriesItem";
import { useState, useEffect } from "react";

const Modelling_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData?.items?.rows)
        return;

    return <div className="ecg_list_flex">
        {pageData.items.rows.map((item) => <Modelling_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Modelling_Data_EntriesList;