import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import "./GenderBar.css";
import { fetchGender } from '../http/Data_API/Gender_Data_API';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Age_Bar from './AgeBar';

const Gender_Bar = observer(({ onGenderChanged, onAgeChanged }) => {
    const { t } = useTranslation()
    const { user } = useContext(Context);
    const [genders, setGenders] = useState(null);
    const location = useLocation();
    const history = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [selectedGender, setSelectedGender] = useState(searchParams.has('gender') ? parseInt(searchParams.get('gender')) : null);

    useEffect(() => {
        fetchGender().then((genders) => setGenders(genders));
    }, [selectedGender]);

    if (!genders)
        return;

    return <div className='filters_block'>
        <div className='gender_list'>
            <div className='gender_list_title_filter'>{t('filter_params')}</div>
            <hr className='ecg_data_entries_hr'></hr>
            <div className='gender_list_title'>
                {t("select_gender")}
            </div>
            {genders.map((gender) => (
                <div
                    className={`gender_button${(selectedGender && selectedGender == gender.id) ? ' active' : ''}`}
                    key={gender.id}
                    onClick={() => { onGenderChanged(gender.id); setSelectedGender(gender.id); searchParams.set('gender', gender.id); history(`?${searchParams.toString()}`) }}
                >
                    {gender['name_' + user.language]}
                </div>
            ))}
        </div>
        {onAgeChanged !== undefined && <div className='age_bar'>
            <Age_Bar onAgeChanged={onAgeChanged} />
        </div>}
    </div>;

});


export default Gender_Bar;
