import {makeAutoObservable} from "mobx";

export default class Gistology_Data_Store {
    constructor(setPage) {

        this._gistology_categories = []
        this._selectedGistology_Categories = {}

        this._gistology_data = []

        this.setPage = setPage;

        makeAutoObservable(this)
    }

    //-----------

//===========
// begin [ Gistology_Categories ]

    setGistology_Categories(data) {
        this._gistology_categories = data
    }

    get gistology_categories() {
        return this._gistology_categories
    }

    setSelectedGistology_Categories(data) {
        this.setPage(1)
        this._selectedGistology_Categories = data
    }

    get selectedGistology_Categories() {
        return this._selectedGistology_Categories
    }

// end [ Gistology_Categories ]
//===========

//-----------

//===========
// begin [ Gistology_Data ]

    setGistology_Data(data) {
        this._gistology_data = data
    }

    get gistology_data() {
        return this._gistology_data
    }

// end [ Gistology_Data ]
//===========

//-----------

}
