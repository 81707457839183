import {makeAutoObservable} from "mobx";

import TestExtra_Data_Store from "./Data/TestExtra_Data_Store";
import ECG_Data_Store from "./Data/ECG_Data_Store";
import Biology_Data_Store from "./Data/Biology_Data_Store";
import Endoscopy_Data_Store from "./Data/Endoscopy_Data_Store.js"
import Gistology_Data_Store from "./Data/Gistology_Data_Store";
import Patanatomy_Data_Store from "./Data/Patanatomy_Data_Store";

export default class Data_Store {
    constructor() {
        this._pimu_categories = []
        this._selectedPimu_Categories = {}

//-----------

        this.testExtra = new TestExtra_Data_Store(this.setPage.bind(this))

//-----------

        this.ecg = new ECG_Data_Store(this.setPage.bind(this))

//-----------

        this.biology = new Biology_Data_Store(this.setPage.bind(this))

//-----------

        this.patanatomy = new Patanatomy_Data_Store(this.setPage.bind(this))

//-----------

        this.gistology = new Gistology_Data_Store(this.setPage.bind(this))

//-----------

        this.endoscopy = new Endoscopy_Data_Store(this.setPage.bind(this))

//-----------

        this._genders = []
        this._selectedGender = {}

        this._entities = []
        this._selectedEntities = {}

        this._page = 1
        this._totalCount = 0
        this._limit = 3
        makeAutoObservable(this)
    }

//===========
// begin [ Pimu_Categories ]

    setPimu_Categories(data) {
        this._pimu_categories = data
    }

    get pimu_categories() {
        return this._pimu_categories
    }

    setSelectedPimu_Categories(data) {
        this.setPage(1)
        this._selectedPimu_Categories = data
    }

    get selectedPimu_Categories() {
        return this._selectedPimu_Categories
    }

// end [ Pimu_Categories ]
//===========

//-----------



//-----------

//===========
// begin [ Gender ]

    setGenders(data) {
        this._genders = data
    }

    get genders() {
        return this._genders
    }

    setSelectedGender(data) {
        this.setPage(1)
        this._selectedGender = data
    }

    get selectedGender() {
        return this._selectedGender
    }

// end [ Gender ]
//===========

//-----------



//-----------

//===========
// begin [ Entities ]

    setEntities(data) {
        this._entities = data
    }

    get entities() {
        return this._entities
    }

    setSelectedEntities(data) {
        this.setPage(1)
        this._selectedEntities = data
    }

    get selectedEntities() {
        return this._selectedEntities
    }

// end [ Entities ]
// ===========

// -----------

    setTotalCount(data) {
        this._totalCount = data
    }

    get totalCount() {
        return this._totalCount
    }

//-----------

    setPage(data) {
        this._page = data
    }

    get page() {
        return this._page
    }

//-----------

    get limit() {
        return this._limit
    }

//-----------

}
