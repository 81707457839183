export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const REGISTRATION_ROUTE = '/registration';
export const ACCOUNT_ROUTE = '/account';
export const PASSWORD_RESTORE_ROUTE = '/restore';

//-----------

export const ABOUT_APPLICATION_ROUTE = '/about_application'
export const CONTACTS_ROUTE = '/contacts'
export const SUPPORT_ROUTE = '/support'
export const SUPPORT_GUIDE_ROUTE = '/support_guide'
export const DEMO_VERSION_ROUTE = '/demo_version'
export const System_Requirements_ROUTE = '/system_requirements';
export const Agreement_ROUTE = '/agreement';

//-----------

export const Epidemics_Map_ROUTE = '/epidemics'

//-----------

export const START_ROUTE = '/'

//-----------

export const PIMU_CATEGORIES_ROUTE = '/pimu'

//-----------

export const ECG_CATEGORIES_ROUTE = '/ecg'
export const ECG_DATA_ENTRIE = '/entrie'

//-----------

export const TestExtra_CATEGORIES_ROUTE = '/testExtra'
export const TestExtra_DATA_ENTRIE = '/entrie'

//-----------

export const Biology_CATEGORIES_ROUTE = '/biology'
export const Biology_DATA_ENTRIE = '/entrie'

//-----------

export const Patanatomy_CATEGORIES_ROUTE = '/patanatomy'
export const Patanatomy_DATA_ENTRIE = '/entrie'

//-----------

export const Gistology_CATEGORIES_ROUTE = '/gistology'
export const Gistology_DATA_ENTRIE = '/entrie'

//-----------

export const Endoscopy_CATEGORIES_ROUTE = '/endoscopy'
export const Endoscopy_DATA_ENTRIE = '/entrie'

//-----------

export const KLD_CATEGORIES_ROUTE = '/kld';
export const KLD_DATA_ENTRIE = '/entrie';

//-----------

export const Microbiology_CATEGORIES_ROUTE = '/microbiology';
export const Microbiology_DATA_ENTRIE = '/entrie';

//-----------

export const SkinDisease_CATEGORIES_ROUTE = '/skindisease';
export const SkinDisease_DATA_ENTRIE = '/entrie';

//-----------

export const Testing_PAGE_ROUTE = '/testing';

//-----------

export const Modelling_CATEGORIES_ROUTE = '/modelling';
export const Modelling_DATA_ENTRIE = '/entrie';

//-----------

export const RadiologyDiag_CATEGORIES_ROUTE = '/radiology';
export const RadiologyDiag_DATA_ENTRIE = '/entrie';

//-----------

export const Botany_CATEGORIES_ROUTE = '/botany';
export const Botany_DATA_ENTRIE = '/entrie';

//-----------

export const Ultrasound_CATEGORIES_ROUTE = '/ultrasound';
export const Ultrasound_DATA_ENTRIE = '/entrie';

//-----------

export const Genetics_CATEGORIES_ROUTE = '/genetics';
export const Genetics_DATA_ENTRIE = '/entrie';

//-----------

export const Terms_PAGE_ROUTE = '/terms';

//-----------

export const Subscribe_PAGE_ROUTE = '/subscribe';

//-----------

const MoodleURL_SDOPIMU = process.env.REACT_APP_MOODLE_URL_SDO;
const MoodleClientID_SDOPIMU = process.env.REACT_APP_MOODLE_CLIENT_ID_SDO;

const MoodleURL_IVGMU = process.env.REACT_APP_MOODLE_URL_IVGMU;
const MoodleClientID_IVGMU = process.env.REACT_APP_MOODLE_CLIENT_ID_IVGMU;

const MoodleURL_KurskSMU = process.env.REACT_APP_MOODLE_URL_KURSKSMU;
const MoodleClientID_KurskSMU = process.env.REACT_APP_MOODLE_CLIENT_ID_KURSKSMU;

//  Способы авторизации через Moodle. Ключ - это идентификатор клиента, а значением у него будет URL сайта.
export const MoodleAuthTypes = {
    [MoodleClientID_SDOPIMU] : MoodleURL_SDOPIMU,
    [MoodleClientID_IVGMU]: MoodleURL_IVGMU,
    [MoodleClientID_KurskSMU]: MoodleURL_KurskSMU,
};