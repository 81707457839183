import { observer } from "mobx-react-lite";
import Radiology_Data_EntriesItem from "./Radiology_Data_EntriesItem";

const Radiology_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='ecg_list_flex'>
        {pageData.items.rows.map((item) => <Radiology_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Radiology_Data_EntriesList;