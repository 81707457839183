import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Context } from "../index";

import './TopCaseNavigation.css';

//  Навигация по кейсам подкатегории в нижней части страницы.
//  На вход компоненту нужен лишь один аргумент (props):
//  *   pageData -- объект с информацией о том, где находится этот кейс (элемент категории), из которого нужны:
//      previousItem -- объект с информацией о предыдущем кейсе;
//      nextItem -- объект с информацией о следующем кейсе;
//      category -- объект с информацией о связанной с этим кейсом категорией.
//  TODO:   сделать что-то с аттрибутом 'to' у 'NavLink'. В настоящий момент, если значение аттрибута пустое - то его значение подменяется текущим pathname, что вызовет перезагрузку страницы при нажатии на ссылку.
const TopCaseNavigation = ({ pageData }) => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const searchParams = new URLSearchParams(useLocation().search);
    const searchParamsForPrevious = new URLSearchParams(useLocation().search);
    const searchParamsForNext = new URLSearchParams(useLocation().search);

    if (!pageData)
        return;

    searchParams.set('page', pageData.item.page);
    searchParamsForPrevious.delete('page');
    searchParamsForNext.delete('page');

    const ScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    //  Предусмотреть режим демо-версии.
    //  Навигация лишь по тем кейсам, что доступны для демо, если у пользователя есть такое ограничение.
    const userIsDemo = user.access_level.demo_access;
    if (userIsDemo) {
        if (pageData.nextItem && !pageData.nextItem?.demo_access)
            pageData.nextItem = null;
        if (pageData.previousItem && !pageData.previousItem?.demo_access)
            pageData.previousItem = null;
    }

    return <div className='top_button_block'>
        <NavLink className={`top_case_button previous ${pageData.previousItem == null ? 'disabled' : ''}`} onClick={() => ScrollToTop()} to={pageData.previousItem ? `/${pageData.category.route}/entrie/${pageData.previousItem?.id}?${searchParamsForPrevious.toString()}` : null}>{t('previous')}</NavLink>
        <NavLink className='top_case_button wide' onClick={() => ScrollToTop()} to={`/${pageData.category.route}/${pageData.category.id}?${searchParams.toString()}`}>{t('to_category')}</NavLink>
        <NavLink className={`top_case_button next ${pageData.nextItem == null ? 'disabled' : ''}`} onClick={() => ScrollToTop()} to={pageData.nextItem ? `/${pageData.category.route}/entrie/${pageData.nextItem?.id}?${searchParamsForNext.toString()}` : null}>{t('next')}</NavLink>
    </div>;
};

export default TopCaseNavigation;