import React, { useState, useEffect } from "react";
import OpenSeadragon from "openseadragon";
import "./DZI_ImageViewer.css"

export const DZI_ImageViewer = ({ images }) => {

    useEffect( () => {
        document.querySelectorAll('.openseadragon-container').forEach( (e) => e.remove() );

        OpenSeadragon({
            id: "openseadragon-viewer",
            prefixUrl: "/openseadragon/images/",
            tileSources: images, // Передача массива путей к изображениям
            sequenceMode: true, // Включение режима последовательности
            showReferenceStrip: true, // Отображение горизонтальной полосы прокрутки
        });
    }, [ images ] );

    //  TODO: пока что простой вариант, который отключает контекстное меню у изображений. Необходимо доработать, чтобы изображения получались в зашифрованном виде и отображались так-же.
    return <div className='openseadragon' onContextMenu={ e => e.preventDefault() } id='openseadragon-viewer'></div>;
};

export default DZI_ImageViewer;

/*
class DZI_ImageViewer extends Component {
    

    componentDidMount() {
        console.log(`Mount`);
        console.log(this.props);

        const { images } = this.props;

        const viewer = OpenSeadragon({
            id: "openseadragon-viewer",
            prefixUrl: "/openseadragon/images/",
            tileSources: images, // Передача массива путей к изображениям
            sequenceMode: true, // Включение режима последовательности
            showReferenceStrip: true, // Отображение горизонтальной полосы прокрутки
        });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(`DidUpdate`);
        console.log(prevProps);
    }

    render() {
        console.log(`Render`);
        console.log(this.props);
        return <div className="openseadragon" id="openseadragon-viewer" />;
    }
}

export default DZI_ImageViewer;
*/