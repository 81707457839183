import React, { useContext, useEffect, useState } from 'react';
import Gender_Bar from "../../components/Gender_Bar";
import Microbiology_Data_EntriesList from "../../components/Microbiology/Microbiology_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useLocation } from "react-router-dom";
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { PreparePage } from '../../utils/PageDataPrepare';
import Pages from '../../components/Pages';
import { useTranslation } from "react-i18next";
import { Microbiology_EntriesPerPage } from './Microbiology_Categories_Page';

const Microbiology_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Microbiology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData.items)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/microbio_icon.png' alt='page_title_icon'></img>{t('microbiology_title')}
        </div>
        <div className='content_breadcrumbs'>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
        </div>
        <div className='content_cases'>
            <div className='content_body'>
                <Microbiology_Data_EntriesList pageData={pageData} />
                <div className='content_Pages'>
                    <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                </div>
            </div>
        </div>
    </>
});

export default Microbiology_Data_EntriesTable_Page;
