// import React, { useContext, useState, useEffect } from 'react';
// import { useNavigate } from "react-router-dom";
// import { observer } from "mobx-react-lite";
// import "./Support_Page.css"
// import i18n from 'i18next';
// import { useTranslation } from "react-i18next";

// const Documentation_Page = observer(() => {
//     const history = useNavigate()

//     const { t } = useTranslation();
//     const changeLanguage = (lng) => {
//         i18n.changeLanguage(lng);
//         localStorage.setItem('i18nextLng', lng);
//     };




//     return (
//         <div className='support_page'>
//             <div className='support_grid'>
//                 <div className='support_title'>{t('support_title')}
//                     <img className='support_settings_icon' src="icons/support/settings.png" alt="support_settings_icon" />
//                 </div>
//                 <div className='support_title_text'>{t('support_title_text')}
//                 </div>
//                 <div className='support_account'>
//                     <div className='support_icon'>
//                         <img src="icons/support/account.png" alt="support_icon" />
//                     </div>{t('support_faq_account')}

//                     <br></br>
//                     <br></br>
//                     {/* <a href="/support_guide" className='support_link'>
//                         <div className='support_data_icon'>
//                             <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('support_title_main')}
//                         </div>
//                     </a> */}
//                     <a href="/support_guide" onClick={(e) => openLink(e, 'Account')} className='support_link'>
//                         <div className='support_data_icon'>
//                             <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('support_acount')}
//                         </div></a>
//                 </div>
//                 <div className='support_medical_data'>
//                     <div className='support_icon'>
//                         <img src="icons/support/medical_data.png" alt="medical_data_icon" />
//                     </div>
//                     {t('support_medical_info')}
//                     <br></br>
//                     <br></br>
//                     <a href="/support_guide" onClick={(e) => openLink(e, 'Data')} className='support_link' >
//                         <div className='support_data_icon'>
//                             <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('support_med_data')}
//                         </div></a>
//                 </div>
//                 <div className='support_faq'>
//                     <div className='support_icon'>
//                         <img src="icons/support/faq.png" alt="faq_icon" />
//                     </div>
//                     {t('support_faq')}
//                     <br></br>
//                     <br></br>
//                     <a href="/support_guide" onClick={(e) => openLink(e, 'Info')} className='support_link'>
//                         <div className='support_data_icon'>
//                             <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('support_data_icon')}
//                         </div></a>
//                 </div>
//                 <div className='support_message'>
//                     {t('support_message')}
//                     <img className='support_emoji' src='icons/support/emoji.png' alt='emoji' />
//                     <div className='support_message_text'>
//                         {t('support_message_text')}</div>
//                     <a href="mailto:support_atlas@pimunn.net">
//                         <button className='support_button'>
//                             {t('support_contact_button')}
//                         </button>
//                     </a>


//                 </div>
//                 <div className='support_volunteers'>
//                     {t('support_volunteers')}
//                     <div className='support_volunteers_text'>
//                         {t('support_volunteers_text')}
//                         <img className='support_emoji_doctor' src='icons/support/emoji_doctor.png' alt='emoji' />
//                     </div>
//                     <br></br>
//                     <br></br>
//                     <br></br>
//                     <a href="/support_guide" onClick={(e) => openLink(e, 'Help')} className='support_link'>{t('support_link_more')}</a>
//                 </div>
//                 <div className='support_demo_request'>{t('support_demo_request')}
//                     <br></br>
//                     <br></br>
//                     <br></br>
//                     <br></br>
//                     <a href="/support_guide" onClick={(e) => openLink(e, 'Buy')} className='support_link'>{t('support_link')}</a>
//                     <br></br>
//                 </div>
//             </div>
//         </div>
//     );
// });

// export default Documentation_Page;


import React from 'react';
import { observer } from "mobx-react-lite";
import "./Support_Page.css";
import { useTranslation } from "react-i18next";

const Documentation_Page = observer(() => {
    const { t } = useTranslation();

    return (
        <div className='support_page'>
            <div className='support_grid'>
                <div className='support_title'>{t('support_title')}
                    <img className='support_settings_icon' src="icons/support/settings.png" alt="support_settings_icon" />
                </div>
                <div className='support_title_text'>{t('support_title_text')}</div>

                <div className='support_account'>
                    <div className='support_icon'>
                        <img src="icons/support/account.png" alt="support_icon" />
                    </div>
                    {t('support_faq_account')}
                    <br /><br />
                    <a href="/support_guide#Account" className='support_link'>
                        <div className='support_data_icon'>
                            <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('support_acount')}
                        </div>
                    </a>
                </div>

                <div className='support_medical_data'>
                    <div className='support_icon'>
                        <img src="icons/support/medical_data.png" alt="medical_data_icon" />
                    </div>
                    {t('support_medical_info')}
                    <br /><br />
                    <a href="/support_guide#Data" className='support_link'>
                        <div className='support_data_icon'>
                            <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('support_med_data')}
                        </div>
                    </a>
                </div>

                <div className='support_faq'>
                    <div className='support_icon'>
                        <img src="icons/support/faq.png" alt="faq_icon" />
                    </div>
                    {t('support_faq')}
                    <br /><br />
                    <a href="/terms" className='support_link'>
                        <div className='support_data_icon'>
                            <img src="icons/support/support_data_icon.svg" alt="support_data_icon" />{t('footer_title_terms')}
                        </div>
                    </a>
                </div>

                <div className='support_message'>
                    {t('support_message')}
                    <img className='support_emoji' src='icons/support/emoji.png' alt='emoji' />
                    <div className='support_message_text'>{t('support_message_text')}</div>
                    <a href="mailto:support_atlas@pimunn.net">
                        <button className='support_button'>{t('support_contact_button')}</button>
                    </a>
                </div>

                <div className='support_volunteers'>
                    {t('support_volunteers')}
                    <div className='support_volunteers_text'>
                        {t('support_volunteers_text')}
                        <img className='support_emoji_doctor' src='icons/support/emoji_doctor.png' alt='emoji' />
                    </div>
                    <br /><br /><br />
                    <a href="/support_guide#Help" className='support_link'>{t('support_link_more')}</a>
                </div>

                <div className='support_demo_request'>{t('support_demo_request')}
                    <br /><br /><br /><br />
                    <a href="/support_guide#Buy" className='support_link'>{t('support_link')}</a>
                    <br />
                </div>
            </div>
        </div>
    );
});

export default Documentation_Page;
