import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./ECG_Data_Options_Table.css"
import { useTranslation } from "react-i18next";
import { Context } from "../../index";


const ECG_Data_Options_Table = ({ data }) => {
    const { user } = useContext(Context)
    const { t } = useTranslation();

    if (data.length === 0) {
        return (<div></div>);
    }

    let columnDictionary

    switch (user.language) {
        case 'ru':
            columnDictionary = {
                P_sec: { name: 'P', unit: 'сек' },
                PQ_sec: { name: 'PQ', unit: 'сек' },
                QRS_sec: { name: 'QRS', unit: 'сек' },
                AQRS_grad: { name: 'Ось QRS', unit: 'град' },
                CHSS_yd_min: { name: 'ЧСС', unit: 'уд. в мин.' },
                QT_kor_sec: { name: 'QTc', unit: 'сек' },
            }
            break;
        case 'en':
            columnDictionary = {
                P_sec: { name: 'P', unit: 'sec' },
                PQ_sec: { name: 'PQ', unit: 'sec' },
                QRS_sec: { name: 'QRS', unit: 'sec' },
                AQRS_grad: { name: 'Axis QRS', unit: 'degree' },
                CHSS_yd_min: { name: 'Heart rate', unit: 'bpm' },
                QT_kor_sec: { name: 'QTc', unit: 'sec' },
            }
            break;
        default:
            columnDictionary = {
                P_sec: { name: 'P', unit: 'сек' },
                PQ_sec: { name: 'PQ', unit: 'сек' },
                QRS_sec: { name: 'QRS', unit: 'сек' },
                AQRS_grad: { name: 'Ось QRS', unit: 'degree' },
                CHSS_yd_min: { name: 'ЧСС', unit: 'уд. в мин.' },
                QT_kor_sec: { name: 'QTc', unit: 'сек' },
            }
    }

    const columnNames = Object.keys(columnDictionary);

    if (Array.isArray(data)) {
        return <>
            <br></br>
            <div className="table_ekg_normal_static_parent">
                {columnNames.slice(0, 6).map((columnName, index) => (
                    <div className={`tb_ekg_normal_${index + 1}`} key={columnName}>
                        <div className='tb_ekg_normal_column'>{columnDictionary[columnName].name}</div>
                        {data.map((row, rowIndex) => (
                            <div key={`cell_${columnName}_${rowIndex}`}>
                                <div className='tb_ekg_normal_ed'>{columnDictionary[columnName].unit}</div>
                                <div className='tb_ekg_normal_val'>{row[columnName] !== null ? row[columnName] : '\u00A0'}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    }
};

export default ECG_Data_Options_Table;
