import React, { useContext } from 'react';
import { observer } from "mobx-react-lite";
import Biology_Data_EntriesItem from "./Biology_Data_EntriesItem";

const Biology_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return (
        <div className="dzi_items_flex">
            {pageData.items.rows.map((item) => <Biology_Data_EntriesItem key={item.id} record={item} />)}
        </div>
    );
});

export default Biology_Data_EntriesList;