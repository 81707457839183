import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Subscribe_PAGE_ROUTE } from '../utils/consts';

//  Страница пользовательского соглашения.
//  "позаимствовано" полностью со страницы "руководство".
const Agreement_Page = observer( () => {
    const { t } = useTranslation();

    return <div className='terms_page'>
        <div className='terms_grid'>
            <div className='terms_sidebar'>
                <img src={t('logo_pic_about')} className='terms_sidebar_title_img'></img>
                <div className='terms_sidebar_block'>
                    <div className='terms_sidebar_content'>
                        <img src='/icons/terms/Component 15.png'></img>{t('Maintenance')}</div>
                    <div className='terms_sidebar_menu'>{t('General information')}</div>
                </div>
            </div>
            <div className='terms_main'>
                <div className='terms_main_title'>{t('navbar_title_agreement')}</div>
                <div className='terms_main_block'>
                    <div className='terms_main_block_selected' id="Пользовательское соглашение">
                        <div className='terms_main_desc'>
                        Правообладатель исключительных прав на программу для ЭВМ «Медицинский атлас» (свидетельство о регистрации программы для ЭВМ RU2024665780 дата государственной регистрации в Реестре программ для ЭВМ 05 июля 2024 г), обеспечивающую доступ к базе данных «Медицинский атлас» (свидетельство о регистрации базы данных RU2024670043, дата государственной регистрации в Реестре баз данных 03 июля 2024 г ), содержащей медицинские изображения, описания к ним, и тестовые задания, далее по тексту ПО на условиях простой неисключительной лицензии без предварительной регистрации Пользователя на сайте безвозмездного предоставляет право  на частичное или полное воспроизведение размещенной в базе данных «Медицинский атлас» информации в любой форме и любыми доступными в ПО способами для ограниченного набора данных, помеченных «демо».
                        <br />
                        Полный доступ возможен при приобретении <Link to={Subscribe_PAGE_ROUTE}>подписки</Link>.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >;
});

export default Agreement_Page;