import { observer } from "mobx-react-lite";
import SkinDisease_Data_EntriesItem from "./SkinDisease_Data_EntriesItem";
const SkinDisease_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='ecg_list_flex'>
        {pageData.items.rows.map((item) => <SkinDisease_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default SkinDisease_Data_EntriesList;
