import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SliderProtected from '../../components/ProtectedImageSlider';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import './Microbiology_Data_Entrie_Page.css';
import { Microbiology_EntriesPerPage } from './Microbiology_Categories_Page';

const Microbiology_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();
    const [descriptionIndex, setDescriptionIndex] = useState(0);

    useEffect(() => {
        PrepareItemPage(location, user.language, null, Microbiology_EntriesPerPage).then((data) => {
            setPageData({
                ...data,
                item: {
                    ...data.item,
                    description: {
                        ru: data.item.description_ru.split('|'),
                        en: data.item.description_en.split('|'),
                    },
                },
            });
        });
    }, [location, user]);

    // При изменении выбранного изображения, изменить описание.
    const HandleImageChange = (imageIndex) => {
        setDescriptionIndex(imageIndex);
    };

    if (!pageData || !pageData?.item) return null;

    // Наличие данных о заключении в массиве 
    const hasConclusion = pageData.item['conclusion_' + user.language];

    return (
        <>
            <div className="page_content_title">
                <img className="page_title_icon" src="/icons/path_icon.png" alt="page_title_icon" />
                {t('microbiology_title')}
            </div>
            <div className="flex_container">
                <div className="flex_container_title">
                    <div className="item flex_item_1">
                        {pageData.category['name_' + user.language]}
                    </div>
                    <TopCaseNavigation className="top_case_navigation_block" pageData={pageData} />
                </div>
                <hr />
                <div className="case_birth">
                    <div className="case_text_info"></div>
                    <div className='case_flex_onerow'>
                        <div className="case_bold_name">
                            {pageData.item['name_' + user.language]}
                        </div>
                    </div>
                    {hasConclusion && (
                        <>
                            <div className="case_text_info">{t('conclusion')}</div>
                            <div className="case_text_block">
                                {pageData.item['conclusion_' + user.language]}
                            </div>
                        </>
                    )}
                </div>
                <div className="dzi_section">
                    <SliderProtected
                        imageurl={pageData.item.images_path}
                        imagesPerView={1}
                        onSlideChanged={HandleImageChange}
                    />
                </div>
                {descriptionIndex > 0 && (
                    <>
                        <div className='case_text_info'>{t('description')}</div>
                        <div className="case_text_block mb-4">
                            {pageData.item.description[user.language][0]}
                        </div>
                    </>
                )}
                <BottomCaseNavigation pageData={pageData} />
            </div>
        </>
    );
};

export default Microbiology_Data_Entrie_Page;