import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./About_Application_Page.css";
import { useTranslation } from "react-i18next";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const AnimatedBlock = ({ children, delay = 0, className }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay }}
            className={className}
        >
            {children}
        </motion.div>
    );
};

const About_Application_Page = observer(() => {
    const history = useNavigate();
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
    };
    const currentYear = new Date().getFullYear();
    const categories = [
        t("ECG_title"),
        t("Path_title"),
        t("Endoscopy_title"),
        t("Bio_title"),
        t("Dentistry"),
        t("Botanica"),
        t("Skin_diseases"),
        t("Medical_entomology"),
        t("Helminthology"),
        t("Protozoology"),
        t("Histology_title"),
        t("Luch_title"),
        t("Ultrasound_diagnostics"),
        t("Maps_title")
    ];



    return (
        <div className='about_app_page'>
            <div className="about_grid">
                <div className="bar">
                    <AnimatedBlock>
                        <img className='logo_pic_about' src={t('logo_pic_about')} alt="logo_pic_about" />
                    </AnimatedBlock>
                    <AnimatedBlock delay={0.3}><div className='bar_title_mini'>{t('bar_title_mini')}</div></AnimatedBlock>
                </div>
                <div className="about_first_block">
                    <div className='about_main_description'>
                        {t('about_main_description')}
                        <br />
                        <br />{t('bar_title_mini_1')}
                        <br />
                        <br />{t('bar_title_mini_2')}
                    </div>
                </div>
            </div>

            <div className="about_third_block">
                <AnimatedBlock>
                    <div className="about_third_block_text">
                        {t('about_third_block_text')}
                    </div>
                </AnimatedBlock>
                <AnimatedBlock delay={0.2}>
                    <div className="about_third_block_text_2">
                        {t('about_third_block_text_2')}
                    </div>
                </AnimatedBlock>
            </div>
            <div className="about_biology_block">
                <div className="about_biology_grid">
                    <AnimatedBlock>
                        <div className="about_biology_grid_text">
                            {t('about_biology_grid_text')}
                        </div>
                    </AnimatedBlock>
                    <AnimatedBlock delay={0.4}>
                        <img className='about_biology_grid_img2' src='/icons/about/biology_screen_1.png' alt='about_biology_grid_img2' />
                    </AnimatedBlock>
                    <AnimatedBlock delay={0.6}>
                        <img className='about_biology_grid_img3' src='/icons/about/biology_screen_2.png' alt='about_biology_grid_img3' />
                    </AnimatedBlock>
                    <AnimatedBlock delay={0.8}>
                        <img className='about_biology_grid_img4' src='/icons/about/biology_screen_3.png' alt='about_biology_grid_img4' />
                    </AnimatedBlock>
                </div>
            </div>
            <div className="about_text_block">
                <AnimatedBlock>
                    <div className="about_text_block_text">
                        {t('our_mission')}
                    </div>
                </AnimatedBlock>
                <AnimatedBlock delay={0.5}>
                    <div className="about_text_block_text_2">
                        {t('our_mis_2')}
                    </div>
                </AnimatedBlock>
            </div>
            <div className="about_categories_block">
                <AnimatedBlock>
                    <div className="about_categories_block_text">
                        {t('selected_categories')}
                    </div>
                </AnimatedBlock>
                <AnimatedBlock delay={0.5}>
                    <div className="about_categories_block_text_2">
                        {categories.map((category, index) => (
                            <div key={index} className={`about_categories_line line-${(index % 5) + 1}`}>
                                {category}
                            </div>
                        ))}
                    </div>
                </AnimatedBlock>
            </div>
            <div className='about_categories_images'>
                <div className='about_cat_block_grid_1'>
                    <AnimatedBlock delay={0.3} className='about_categories_images_text'>
                        {t('unique_app')}
                    </AnimatedBlock>
                    <AnimatedBlock delay={0.4} className='about_categories_images_text_mini'>
                        {t('digital_app')}
                    </AnimatedBlock>
                </div>
                <div className='about_cat_block_grid_2'>
                    <AnimatedBlock className='about_categories_images_text_sec' delay={0.5}>{t('study')}</AnimatedBlock>
                    <AnimatedBlock className='about_categories_images_text_sec' delay={0.6}>{t('navbar_title_testing')}</AnimatedBlock>
                </div>
            </div>
            <div className="about_contacts_block">
                <AnimatedBlock delay={0.3}>
                    <div className="about_contacts_block_text">
                        {t('contact_us_about')}
                        <br></br>
                        <a href="mailto:sale@pimunn.net">
                            <button className='about_simple_button'>
                                {t('support_contact_button')}
                            </button>
                        </a>
                        <div className='about_simple_text'>{t('start_page_title')} © {t('footer_title_copyright')} {currentYear}</div>
                    </div>
                </AnimatedBlock>
            </div>
        </div>
    );
});

export default About_Application_Page;
