import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { ClenseNumeral, PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { SkinDisease_EntriesPerPage } from './SkinDisease_Categories_Page';
import './SkinDisease_Data_Entrie_Page.css';
import SliderProtected from '../../components/ProtectedImageSlider';

const SkinDisease_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();
    const selectedGender = new URLSearchParams(location.search).get('gender');
    const [descriptionIndex, setDescriptionIndex] = useState(0);

    useEffect(() => {
        PrepareItemPage(location, user.language, selectedGender, SkinDisease_EntriesPerPage).then((data) => {
            setPageData(data);
        });
    }, [location, selectedGender, user]);

    if (!pageData || !pageData.item) return null;

    return (
        <>
            <div className="page_content_title">
                <img className='page_title_icon' src='/icons/hands_icon.png' alt='page_title_icon'></img>{t("skindisease_title")}
            </div>
            <div className="flex_container">
                <div className="flex_container_title">
                    <TopCaseNavigation className="top_case_navigation_block" pageData={pageData} />
                    <div className="item flex_item_1">
                        {pageData.category ? pageData.category['name_' + user.language] : ''}
                    </div>

                </div>
                <hr />
                <div className="case_birth">
                    <div className='case_flex_onerow'>
                        <div className="case_bold_name">
                            {pageData.item['name_' + user.language]}
                        </div>
                    </div>
                    {/* Скрыл целиком поле с возрастом */}
                    {/* <div className='gender_age_bar_SkinDisease'>
                        <div className='gender'>
                            {pageData.item.gender
                                ? (user.language === 'ru'
                                    ? pageData.item.gender.name_ru
                                    : pageData.item.gender.name_en)
                                : t('gender_not_specified')}
                        </div>

                        <div className='age'>
                            {pageData.item.age ? (
                                <>
                                    {pageData.item.age}&nbsp;
                                    {ClenseNumeral(pageData.item.age, [t('num_year_1'), t('num_year_2'), t('num_year_5')])}
                                </>
                            ) : (
                                <>
                                    {t('age_preview')}&nbsp;{t('case_age_unspeciied')}
                                </>
                            )}
                        </div>
                    </div> */}

                </div>
                <div className='case_flex'>
                    <div className='case_conclusion'>
                        <div className='case_text_info'>{t('description')}</div>
                        <div className='case_text_block_bold'>
                            {pageData.item['description_' + user.language]}</div>
                    </div>
                </div>
                <div className='case_flex'>
                    <div className='case_conclusion'>
                        <div className='case_text_info'>{t('conclusion')}</div>
                        <div className='case_text_block_bold'>
                            {/* <div className='case_icon'>
                                <img src='/icons/case_icon.svg'></img>
                            </div> */}
                            {pageData.item['conclusion_' + user.language]}</div>
                    </div>
                </div>
                <div className="dzi_section">
                    <SliderProtected
                        imageurl={pageData.item.images_path}
                        imagesPerView={1}
                        onSlideChanged={ i => setDescriptionIndex(i) }
                    />
                </div>

                {pageData.item['dynamics_' + user.language] && <div className='case_text_block_bold mb-4'>
                    {/* <div className='case_icon'>
                                <img src='/icons/case_icon.svg'></img>
                            </div> */}
                    {pageData.item['dynamics_' + user.language]}
                </div> }

                <BottomCaseNavigation pageData={pageData} />
            </div>
        </>
    );
};

export default SkinDisease_Data_Entrie_Page;
