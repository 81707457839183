import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import DZI_Images_Display from "../../components/DZI_OpenSeadragon/DZI_Images_Display";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import All_Pages_Data_Enrie_Page from "../All_Pages_Data_Entrie_Page.css";

import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Patanatomy_EntriesPerPage } from './Patanatomy_Categories_Page';


const Patanatomy_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();

    useEffect(() => {
        PrepareItemPage(location, user.language, null, Patanatomy_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.category || !pageData?.item)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/path_icon.png' alt='page_title_icon'></img>{t("Path_title")}
        </div>
        <div className="flex_container" key={pageData.id}>
            <div className='flex_container_title'>
                <div className='item flex_item_1'>
                    {pageData.category['name_' + user.language]}
                </div>
                <div className='top_case_navigation_block'>
                    <TopCaseNavigation pageData={pageData} />
                </div>

            </div>
            <hr />
            <div className='case_birth'>
                <div className='case_text_info'></div>
                <div className='case_bold_name'>{pageData.item['description_' + user.language]}</div>
                <div className='case_text_block'>{pageData.item['full_description_' + user.language]}</div>
                <div className='case_text_info'>{t('description')}</div>
                <div className='case_text_block'>{pageData.item['full_description2_' + user.language]}</div>
            </div>
            <div className='dzi_section'>
                <DZI_Images_Display images={pageData.item.images_path_dzi} />
            </div>
            <BottomCaseNavigation pageData={pageData} />
        </div>;</>
};

export default Patanatomy_Data_Entrie_Page;
