import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import './Terms_Page.css';

const Terms_Page = observer(() => {
    const history = useNavigate();
    const { t } = useTranslation();



    function openMenu(evt, selectedName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("terms_main_block_selected");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("terms_sidebar_menu");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(selectedName).style.display = "block";
        evt.currentTarget.className += " active";
    };

    useEffect(() => {
        const fakeEvent = { currentTarget: { className: "" } };
        openMenu(fakeEvent, 'Общие сведения');
    }, []); // Пустой массив зависимостей означает, что эффект будет выполнен только при первом рендере


    return <>
        <div className='terms_page'>
            <div className='terms_grid'>
                <div className='terms_sidebar'>
                    <img src={t('logo_pic_about')} className='terms_sidebar_title_img'></img>
                    <div className='terms_sidebar_block'>
                        <div className='terms_sidebar_content'>
                            <img src='/icons/terms/Component 15.png'></img>{t('Maintenance')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Общие сведения')}>{t('General information')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Основные разработки документа')}>{t('Main Document Developments')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Срок действия инструкции')}>{t('Validity period of the instructions')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Категории медицинского атласа')}>{t('Categories of the medical atlas')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Демо-версия')}>{t('Demo version')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Мобильная версия')}>{t('Mobile version')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Система поиска в категориях')}>{t('Search system')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Настройка аккаунта')}>{t('Account settings')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Приобретение подписки')}>{t('Subscription')}</div>
                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Техническая поддержка пользователей')}>{t('Technical support for users')}</div>
                    </div>
                </div>
                <div className='terms_main'>
                    <div className='terms_main_title'>{t('User Instructions')}</div>
                    <div className='terms_main_block'>
                        <div className='terms_main_block_selected' id="Общие сведения">
                            <button className='user_guide_block_button'
                                onClick={() => window.open(t('User_guide_pdf'), "_blank")}
                            >
                                <img src='/icons/button_pdf_file.svg' />
                                <br></br>
                                <br></br>
                                {t("pdf_open")}
                            </button>
                            <div className='terms_main_name_title' >{t('General information')}</div>
                            <div className='terms_main_desc'>
                                {t("The web application is designed")}
                                <br></br>
                                <br></br>
                                <ul>
                                    <li>{t('ECG_title')}</li>
                                    <li>{t('Endoscopy_title')}</li>
                                    <li>{t('Path_title')}</li>
                                    <li>{t('Bio_title')} ({t('Protozoology')}, {t('Helminthology')}, {t('Medical_entomology')})</li>
                                    <li>{t('Histology_title')}</li>
                                    <li>{t('Clinical_lab_diagnistics')}</li>
                                    <li>{t('Luch_title')}</li>
                                    <li>{t('microbiology_title')}</li>
                                    <li>{t('Botanica')}</li>
                                    <li>{t('Dentistry')}</li>
                                    <li>{t('Skin_diseases')}</li>
                                    <li>{t("Ultrasound_diagnostics")}</li>
                                </ul>
                                {t('The area of application')}                            </div>
                        </div>
                        <div className='terms_main_block_selected' id="Основные разработки документа">
                            <div className='terms_main_name_title'>{t('Main Document Developments')}</div>

                            <div className='terms_main_desc'>
                                {t('This instruction is developed')}
                                <br></br>
                            </div>
                        </div>
                        <div className='terms_main_block_selected' id="Срок действия инструкции">
                            <div className='terms_main_name_title'>2. {t('Validity period of the instructions')}</div>
                            <div className='terms_main_desc'>
                                {t('2.1')}                                <br></br>
                                <br></br>
                                {t('2.2')}                              </div>
                        </div>
                        <div className='terms_main_block_selected' id="Категории медицинского атласа">
                            <div className='terms_main_name_title'>4. {t('Categories of the medical atlas')}</div>
                            <img className="screen_menu" src={t('screens_menu')}></img>

                            <div className='screen_string'>{t('Figure 3.3')}</div>
                            <div className='terms_main_desc'>
                                {t("The atlas includes many categories")}
                                <br></br>
                                <br></br>
                                <ul>
                                    <b>
                                        <li>{t('ECG_title')}</li>
                                        <li>{t('Endoscopy_title')}</li>
                                        <li>{t('Path_title')}</li>
                                        <li>{t('Bio_title')} ({t('Protozoology')}, {t('Helminthology')}, {t('Medical_entomology')})</li>
                                        <li>{t('Histology_title')}</li>
                                        <li>{t('Clinical_lab_diagnistics')}</li>
                                        <li>{t('Luch_title')}</li>
                                        <li>{t('microbiology_title')}</li>
                                        <li>{t('Botanica')}</li>
                                        <li>{t('Dentistry')}</li>
                                        <li>{t('Skin_diseases')}</li>
                                        <li>{t("Ultrasound_diagnostics")}</li>
                                    </b>

                                </ul>
                            </div>
                            <div className='terms_main_desc'>
                                <h3><b>4.1. {t('ECG_title')}</b></h3>
                                <br></br>
                                {t('Includes descriptions of ECG cases')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_filters')}></img>
                                <div className='screen_string'>{t('Figure 4.1')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_menu')}></img>
                                <div className='screen_string'>{t('Figure 4.2')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_case')}></img>
                                <div className='screen_string'>{t('Figure 4.3')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_table')}></img>
                                <div className='screen_string'>{t('Figure 4.4')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_photos')}></img>
                                <div className='screen_string'>{t('Figure 4.5')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.2. {t('Endoscopy_title')}</b></h3>
                                <br></br>
                                {t('Contains photographs')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_gast_case')}></img>
                                <div className='screen_string'>{t('Figure 4.7')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.3. {t('Path_title')}</b></h3>
                                <br></br>
                                {t('Pathological Anatomy Contains photographs')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_pat_menu')}></img>
                                <div className='screen_string'>{t('Figure 4.8')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_pat_case')}></img>
                                <div className='screen_string'>{t('Figure 4.9')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.4. {t('Bio_title')} </b></h3>
                                <br></br>
                                {t('Parasitilogy info')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_par_menu')}></img>
                                <div className='screen_string'>{t('Figure 5')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.5. {t('Medical_entomology')}</b></h3>
                                <br></br>
                                <img className="screen_menu" src={t('screens_arch_case')}></img>
                                <div className='screen_string'>{t('Figure 5.2')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.6. {t('Helminthology')}</b></h3>
                                <br></br>
                                <img className="screen_menu" src={t('screens_his_case')}></img>
                                <div className='screen_string'>{t('Figure 5.3')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.7. {t('Protozoology')}</b></h3>
                                <br></br>
                                <img className="screen_menu" src={t('screens_par_case')}></img >
                                <div className='screen_string'>{t('Figure 5.4')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.8. {t('Histology_title')}</b></h3>
                                <br></br>
                                {t('Histology terms')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_histo_case')} ></img >
                                <div className='screen_string'>{t('Figure 5.5')}</div>
                                <br></br>
                                <br></br>
                                <h3><b>4.9. {t('Clinical_lab_diagnistics')}</b></h3>
                                <br></br>
                                <br></br>
                                {t('KLD Contains photographs')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_kld_menu')} ></img >
                                <div className='screen_string'>{t('Figure 5.6')}</div>
                            </div >
                        </div >
                        <div className='terms_main_block_selected' id="Демо-версия">
                            <div className='terms_main_name_title'>5. {t('Demo version')}</div>
                            <div className='terms_main_desc'>
                                {t('All users are by default granted access only to the demo version of the application.')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_demo_case')}></img>
                                <div className='screen_string'>{t('Figure 5.7')}</div>
                            </div>
                        </div >
                        <div className='terms_main_block_selected' id="Мобильная версия">
                            <div className='terms_main_name_title'>6. {t('Mobile version')}</div>
                            <div className='terms_main_desc'>{t('The web application also has a mobile adaptive version')}
                            </div></div>
                        <div className='terms_main_block_selected' id="Система поиска в категориях">
                            <div className='terms_main_name_title'>7. {t('Search system')}</div>
                            <div className='terms_main_desc'>
                                {t('The application has a built-in simple search')}
                            </div></div>
                        <div className='terms_main_block_selected' id="Настройка аккаунта">
                            <div className='terms_main_name_title'>8. {t('Account settings')}</div>
                            <div className='terms_main_desc'>
                                {t('The application has user account settings')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_set_user')}></img>
                                <div className='screen_string'>{t('Figure 5.8')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_set_user_2')}></img>
                                <div className='screen_string'>{t('Figure 5.9')}</div>
                            </div >
                        </div >
                        <div className='terms_main_block_selected' id="Приобретение подписки">
                            <div className='terms_main_name_title'>9. {t('Subscription')}</div>
                            <div className='terms_main_desc'>
                                {t('By default, the application is available in demo mode')}                                <br></br>
                                <br></br>
                                {t('Subscription is provided by upgrading')} <b>sale@pimunn.net</b>, {t('or mobile phone')} <b>+7(920)002-11-55</b>
                                <br></br>
                                <br></br>
                                {t('subscribs allow')}
                                <br></br>
                                <br></br>
                                {t('subscription names')}
                                <br></br>
                                <br></br>
                                {t('university')}
                                <br></br>
                                <br></br>
                                1. {t('basic')}: {t('up500')}, {t('usage period')} 1 {t('num_year_1')} - 900.000 ₽
                                <br></br>
                                2. {t('opt')}: {t('from500')}, {t('usage period')} 1 {t('num_year_1')} - 1.900.000 ₽
                                <br></br>
                                3. {t('premium')}: {t('nolimit')}, {t('usage period')} 10 {t('num_year_5')} - 5.000.000 ₽
                                <br></br>
                                <br></br>
                                {t('college')}
                                <br></br>
                                <br></br>
                                1. {t('basic')}: {t('up500')}, {t('usage period')} 1 {t('num_year_1')} - 630.000 ₽
                                <br></br>
                                2. {t('opt')}: {t('from500')}, {t('usage period')} 1 {t('num_year_1')} - 1.330.000 ₽
                                <br></br>
                                3. {t('premium')}: {t('nolimit')}, {t('usage period')} 10 {t('num_year_5')} - 3.250.000 ₽
                            </div></div>
                        <div className='terms_main_block_selected' id="Техническая поддержка пользователей">
                            <div className='terms_main_name_title'>10. {t('Technical support for users')}</div>
                            <div className='terms_main_desc'>
                                {t('quick_support')}
                                <img className="screen_menu" src={t('screens_support_page')}></img>
                                <div className='screen_string'>{t('Figure 5.10')}</div>                               <br></br>
                                <br></br>
                                <br></br>
                                {t('support_mails')} support_atlas@pimunn.net
                                <br></br>
                                <br></br>
                                {t('greetings support')}
                            </div>

                        </div>
                        <div className='terms_main_block_selected' id="Заключение">
                            <div className='terms_main_name_title'>11. {t('Conclusion')}</div>

                        </div>
                    </div >
                </div >
            </div >
        </div >
    </>;
});

export default Terms_Page; 
