import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {Row} from "react-bootstrap";
import TestExtra_Data_EntriesItem from "./TestExtra_Data_EntriesItem";

const TestExtra_Data_EntriesList = observer(() => {
    const {record} = useContext(Context)
    const rows = [];

    for (let i = 0; i < record.testExtra.testExtra_data.length; i++)
    {
        const gender = record.genders.find(({ id }) => id === record.testExtra.testExtra_data[i].gender)
       // console.log(record.records[i].gender)
        rows.push(<TestExtra_Data_EntriesItem key={record.testExtra.testExtra_data[i].id} record={record.testExtra.testExtra_data[i]} gender = {gender}/>);
    }

    //console.log(record)

    return (
        <Row className="d-flex">{rows}</Row>
    );
});

export default TestExtra_Data_EntriesList;