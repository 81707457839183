import {$authHost, $host} from '../index';
//-----------

//===========
//// begin [ Genetics ]

//===========
// begin [ Genetics_Categories ]

export const fetchGenetics_Categories = async (search, language) => {
    const {data} = await $host.get('api/genetics/genetics_categories', {params: {
            search, language
    }});
    return data;
}

export const fetchOneGenetics_Categories = async (id) => {
    const {data} = await $host.get('api/genetics/genetics_categories/' + id);
    return data;
}

// end [ Genetics_Categories ]
//===========

//-----------

//===========
// begin [ Genetics_Data ]

export const fetchGenetics_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/genetics/genetics_data', {params: {
            search, language, categories_id, page, limit
        }});
    return data;
}

export const fetchOneGenetics_Data = async (id) => {
    const {data} = await $host.get('api/genetics/genetics_data/' + id);
    return data;
}

// end [ Genetics_Data ]
//===========

//// end [ Genetics ]
////===========

//-----------