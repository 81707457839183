import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
// begin [ Gender ]

export const fetchGender = async () => {
    const {data} = await $host.get('api/gender', )
    return data
}

export const fetchOneGender = async (id) => {
    const {data} = await $host.get('api/gender/' + id)
    return data
}

// end [ Gender ]
//===========

//-----------