import React, { useContext, useEffect, useState } from 'react';
import  SkinDisease_Data_EntriesList  from "../../components/SkinDisease/SkinDisease_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { SkinDisease_EntriesPerPage } from './SkinDisease_Categories_Page';
import Gender_Bar from "../../components/Gender_Bar";

const SkinDisease_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const selectedGender = searchParams.get('gender');
    const selectedAges = searchParams.has('ages') ? searchParams.get('ages').split(',') : null;

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, SkinDisease_EntriesPerPage, selectedGender == 3 ? null : selectedGender, null, selectedAges).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData.items)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/hands_icon.png' alt='page_title_icon'></img>{t("skindisease_title")}
        </div>
        <div className='content_breadcrumbs'>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
        </div>
        <div className='content_cases'>
            <div className='content_filters'>
                <Gender_Bar onGenderChanged={(gender) => { user.gender = gender; user.page = 1; }} onAgeChanged={ ({ min, max }) => { user.ages = [ min, max ]; user.page = 1; } } />
            </div>
            <div className='content_body'>
                <SkinDisease_Data_EntriesList pageData={pageData} />
                <div className='content_Pages'>
                    <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                </div>
            </div>
        </div>
    </>
});

export default SkinDisease_Data_EntriesTable_Page;

