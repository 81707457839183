import React, { useContext, useEffect, useState } from 'react';
import { Card, Container } from "react-bootstrap";
import Radiology_Data_EntriesList from "../../components/Radiology/Radiology_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import Gender_Bar from '../../components/Gender_Bar';
import { Radiology_EntriesPerPage } from './Radiology_Categories_Page';

const Radiology_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Radiology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/xray_icon.png' alt='page_title_icon'></img>{t('radiology_title')}
        </div>
        <Container>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            <div className='content_cases'>
                <div className='content_filters'>
                    <Gender_Bar onGenderChanged={(gender) => { user.gender = gender; user.page = 1; }} onAgeChanged={({ min, max }) => { user.ages = [min, max]; user.page = 1; }} />
                </div>
                <div className='content_body'>
                    <Radiology_Data_EntriesList pageData={pageData} />
                    <div className='content_Pages'>
                        <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                    </div>
                </div>
            </div>
        </Container>;</>
});

export default Radiology_Data_EntriesTable_Page;
