import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Biology_CATEGORIES_ROUTE, Biology_DATA_ENTRIE, DEMO_VERSION_ROUTE, } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { useLocation } from 'react-router-dom';

const Biology_Data_EntriesItem = ({ record }) => {
    const { user } = useContext(Context);
    const { t } = useTranslation();

    const history = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if (!record)
        return;

    // прокрутка вверх при 'Вперед'
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !record.demo_access) {
            scrollToTop();
            history(DEMO_VERSION_ROUTE);
            return;
        }

        scrollToTop();
        history(`${Biology_CATEGORIES_ROUTE}${Biology_DATA_ENTRIE}/${record.id}?${searchParams.toString()}`);
    };

    return <div className="case_preview-item1" onClick={TryNavigateToCase}>
        {record.demo_access && user.access_level.demo_access && <div className='demo_version_tif_preview'>{t("Demo")}</div>}
        <img src={record.images_preview_path} alt="image preview" />
        <div className='case_preview_info1'>
            <div className='case_preview_title1'>
                {record['name_' + user.language]}</div>
            <div className='case_preview_latin_name1'>
                {record.name_two}
            </div>
        </div>
    </div>;
};

export default Biology_Data_EntriesItem;