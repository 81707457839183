import React from 'react';


const ECG_Data_Image = ({ url }) => {


    return (
        <img src={url} alt={"изображение_ЭКГ"} />
    );
};

export default ECG_Data_Image;
