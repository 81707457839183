import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";

import Radiology_Examination_Block from './Radiology_Examination_Block';
import './Radiology_Appeal_Block.css';

//  Блок с информацией об обращении пациента.
//  Здесь необходимо вывести:
//  *   номер обращения,
//  *   анамнез,
//  *   лаб. показатели,
//  *   лечение (если проводилось),
//  Также, необходимо вывести все относящиеся к этому обращению проведённые исследования.
const Radiology_Appeal_Block = observer(({ data }) => {
    const { user } = useContext(Context);
    const { t } = useTranslation();

    if (!data)
        return;

    return <div className='radiology_appeal_block'>
        <div className='radiology_appeal_number_flex'>
            {/* {data.appeal.map((appeal, index) => (
             TO DO: Здесь можно завернуть button в map для вывода и всех остальных обращений
        ))} */}
            <button id='' className='radiology_appeal_number_button'>
                {t('radiology_appeal_number')}  {data.treatment_number}
            </button>
        </div>

        <div className='radiology_appeal_anamnesis'>
            <div className='title'>{t('radiology_anamnesis')}</div>
            <div className='text'>{data['anamnesis_' + user.language]}</div>
        </div>

        {data.labdiag_ru && <div className='radiology_appeal_labdiag'>
            <div className='title'>{t('radiology_labdiag')}</div>
            <div className='text'>{data['labdiag_' + user.language]}</div>
        </div>}
        {data.treatment_ru && <div className='radiology_appeal_treatment'>
            <div className='title'>{t('radiology_treatment')}</div>
            <div className='text'>{data['treatment_' + user.language]}</div>
        </div>}

        {data.examinations.map((examination) => <Radiology_Examination_Block key={examination.id} data={examination} />)}

    </div>;
});

export default Radiology_Appeal_Block;