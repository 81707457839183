import { observer } from "mobx-react-lite";
import Endoscopy_Data_EntriesItem from "./Endoscopy_Data_EntriesItem";
import "./Endoscopy_Data_EntriesList.css"
import { useTranslation } from "react-i18next";

const Endoscopy_Data_EntriesList = observer(({ categoryInfo }) => {
    const { t } = useTranslation();

    if (!categoryInfo || !categoryInfo?.items)
        return;

    return (
        <div className='gastroscopy_page'>
            <div className='gastroscopy_flex_container'>
                <img className='gastroscopy_title_icon' src='/icons/user_icon.svg'></img>
                <div className='gastroscopy_title_items'>{t("Patient information")}</div>
            </div>
            <hr></hr>
            <div className='gastroscopy_flex_container'>
                <div className='gastroscopy_table_column'>{t("case")}</div>
                <div className='gastroscopy_table_column'>{t("conclusion")}</div>
            </div>
            <div className='gastroscopy_block'>
                {categoryInfo.items.rows.map((record) => <Endoscopy_Data_EntriesItem key={record.id} record={record} />)}
            </div>
        </div>
    )
});

export default Endoscopy_Data_EntriesList;