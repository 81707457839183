import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation, useParams } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';
import InProcess from "../InProcess"
import BottomCategoriesDisplay from '../../components/BottomCategoriesDisplay';

import { Radiology_EntriesPerPage } from './Radiology_Categories_Page';

const Radiology_StandardCategories_Page = observer( () => {
    const { id } = useParams();
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Radiology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    //  На этой странице нужно вывести только те подкатегории, где та, id которой указан в пути страницы является родительской.

    return <div className='page_content'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/xray_icon.png' alt='page_title_icon'></img>{t('radiology_title')}
        </div>
        <div className='page_content_parent'>
            <div className='page_content_flex'>
                <SidebarCategoriesDisplay categories={pageData?.subCategory} forParent={Number(id)} matchingCategories={pageData?.search?.matches} />
            </div>
            <div className='page_content_rightpage_block'>
                <InProcess />
                <Search_Bar searchSucceeded={pageData?.search.succeeded} />
                <br />
                <div className='page_content_rightpage'>
                    <div className='page_content_title_info'>
                        {t('radiology_desc_main')}
                        <br />  <br />
                        <div className='page_title_category'>
                            {t('radiology_desc_main_title')}
                        </div>

                        <br />
                        {t('radiology_desc_main_1')}
                        <br />  <br />
                        <div className='page_title_category'>
                            {t('radiology_desc_main_title_1')}
                        </div>
                        <br />
                        {t('radiology_desc_main_2')}
                        <br />  <br />
                        <div className='page_title_category'>
                            {t('radiology_desc_title_2')}
                        </div>
                        <br />
                        {t('radiology_desc_main_3')}
                        <br />  <br />
                        <div className='page_title_category'>
                            {t('radiology_desc_title_3')}
                        </div>
                        <br />
                        {t('radiology_desc_main_4')}
                        <br />  <br />
                        <div className='page_title_category'>
                            {t('radiology_desc_title_4')}
                        </div>
                        <br />
                        {t('radiology_desc_main_5')}
                        <br /><br />
                        <div className='page_title_category'>
                            {t('radiology_desc_title_5')}
                        </div>
                        <br />
                        {t('radiology_desc_main_6')}
                    </div>
                </div>
            </div>
        </div>
    </div>;
});

export default Radiology_StandardCategories_Page;