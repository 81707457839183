import {makeAutoObservable} from "mobx";

export default class ECG_Data_Store {
    constructor(setPage) {

        this._ecg_categories = []
        this._selectedECG_Categories = {}

        this._ecg_data = []
        this._selectedECG_Data_Con_Parameters = {}
        this._selectedECG_Data_Options = {}
        this._selectedECG_Data_OptionsDefault = {}

        this._selectedIndex = 0

        this.setPage = setPage;

        this._limit = 3

        makeAutoObservable(this)
    }

//-----------

//===========
// begin [ ECG_Categories ]

    setECG_Categories(data) {
        this._ecg_categories = data
    }

    get ecg_categories() {
        return this._ecg_categories
    }

    setSelectedECG_Categories(data) {
        this.setPage(1)
        this._selectedECG_Categories = data
    }

    get selectedECG_Categories() {
        return this._selectedECG_Categories
    }

// end [ ECG_Categories ]
//===========

//-----------

//===========
// begin [ ECG_Data_Con_Parameters ]

    setSelectedECG_Data_Con_Parameters(data) {
        this._selectedECG_Data_Con_Parameters = data
    }

    get selectedECG_Data_Con_Parameters() {
        return this._selectedECG_Data_Con_Parameters
    }

// end [ ECG_Data_Con_Parameters ]
//===========

//-----------

//===========
// begin [ ECG_Data_Options ]

    setSelectedECG_Data_Options(data) {
        this._selectedECG_Data_Options = data
    }

    get selectedECG_Data_Options() {
        return this._selectedECG_Data_Options
    }

// end [ ECG_Data_Options ]
//===========

//-----------

//===========
// begin [ ECG_Data_OptionsDefault ]

    setSelectedECG_Data_OptionsDefault(data) {
        this._selectedECG_Data_OptionsDefault = data
    }

    get selectedECG_Data_OptionsDefault() {
        return this._selectedECG_Data_OptionsDefault
    }

// end [ ECG_Data_OptionsDefault ]
//===========

//-----------

//===========
// begin [ ECG_Data ]

    setECG_Data(data) {
        this._ecg_data = data
    }

    get ecg_data() {
        return this._ecg_data
    }

// end [ ECG_Data ]
//===========

//-----------

    get limit() {
        return this._limit
    }

//-----------

    setSelectedIndex(data) {
        this._selectedIndex = data
    }

    get selectedIndex() {
        return this._selectedIndex
    }

//-----------

}
