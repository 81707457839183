import { NavLink } from "react-router-dom";
import "./NavigationBreadcrumbs.css"

//  Компонент с панелью навигации (хлебные крошки).
//  Аргументы (props):
//  *   categoryInfo -- ожидается объект с ключами:
//      primaryCategory -- объект с информацией о категории (нужны ключи 'name' для поддерживаемых языков),
//      subCategory -- объект с информацией о подкатегории (также нужны ключи 'name' для поддерживаемых языков).
//  *   language -- идентификатор языка (ru, en, ...).
//  Если не указан язык, то будет использован русский.
const NavigationBreadcrumbs = ({ categoryInfo, language = 'ru' }) => {
    if (!categoryInfo || !categoryInfo?.primaryCategory || !categoryInfo?.subCategory)
        return;

    return <div className='table_link'>
        <div className='table_link_name'>
            <NavLink to={`${categoryInfo.primaryCategory.route}${categoryInfo.subCategory.route}`}>
                {categoryInfo.primaryCategory['name_' + language]}
            </NavLink>
            <img src='/icons/Expand_right.png' className='expand-icon' />
            {categoryInfo.subCategory['name_' + language]}
        </div>
        <hr className="breadcrumbs_hr"></hr>
    </div>;
};

export default NavigationBreadcrumbs;