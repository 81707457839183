import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Botany ]

//===========
// begin [ Botany_Categories ]

export const fetchBotany_Categories = async (search, language) => {
    const {data} = await $host.get('api/botany/botany_categories', {params: {
            search, language
        }});
    return data;
}

export const fetchOneBotany_Categories = async (id) => {
    const {data} = await $host.get('api/botany/botany_categories/' + id);
    return data;
}

// end [ Botany_Categories ]
//===========

//-----------

//===========
// begin [ Botany_Data ]

export const fetchBotany_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/botany/botany_data', {params: {
            search, language, categories_id, page, limit
        }});
    return data;
}

export const fetchOneBotany_Data = async (id) => {
    const {data} = await $host.get('api/botany/botany_data/' + id);
    return data;
}

// end [ Botany_Data ]
//===========

//// end [ Botany ]
////===========

//-----------