const DistributedSEIR = () => {
    return(
        <>
        <p>
            Раздел "Карты эпидемий" веб-приложения "Медицинский атлас" позволяет визуализировать на карте динамику
            распространения эпидемий, используя встроенные математические модели, а также на основе загруженных
            пользователем фактических данных, собранных в ходе регистрации реальной эпидемии.
        </p>
        <p>
            <u>Классическая SEIR-модель</u> &ndash; одна из самых известных в эпидемиологии &ndash; содержит четыре переменные:
            <ul>
                <li><b>S (Susceptible)</b> &ndash; здоровые, при этом ранее не болевшие (восприимчивые)</li>
                <li><b>E (Exposed)</b> &ndash; больные, находящиеся в инкубационном периоде</li>
                <li><b>I (Infected)</b> &ndash; больные, находящиеся в активной фазе заболевания</li>
                <li><b>R (Recovered)</b> &ndash; здоровые, при этом ранее болевшие и уже выздоровевшие</li>
            </ul>
        </p>
        <p>
            Каждая из этих переменных отражает значение доли населения, относящейся к соответствующему состоянию.
            Их динамика описывается следующей системой дифференциальных уравнений:
        </p>
        <p>
            <center><img src="\Epidemics\SEIR_equations.svg" alt="" width={150} /></center>
        </p>
        <p>
            В качестве начальных значений переменных используются <b>E</b> = <b>R</b> = 0, а <b>S</b> и <b>I</b> берутся из того расчета, что
            один из жителей (нулевой пациент) является инфицированным (<b>I</b>), а все остальные &ndash; восприимчивыми (<b>S</b>).
            Коэффициенты <b>&beta;</b>, <b>&sigma;</b> и <b>&gamma;</b> выражают скорости инфицирования контактных лиц, перехода заболевания
            из инкубационного периода в активную фазу и выздоровления, соответственно.
        </p>
        <p>
            Предложенная <u>распределенная SEIR-модель</u> отличается тем, что решение этой системы дифференциальных
            уравнений производится для каждого из жилых домов города в отдельности, при этом для перехода <b>S</b> &rarr; <b>E</b> при
            передаче инфекции учитывается популяция домов, расположенных в пределах радиуса инфицирования <b>r</b>,
            который является дополнительным параметром этой модели наряду с координатами домов и их населением.
        </p>
        <p>
            Полученные в результате моделирования по заданному городу данные отображаются в виде тепловой карты.
            Кнопка "Слои" позволяет выбрать интересующую переменную для просмотра ее динамики на карте города,
            а также суммарной динамики на представленном графике.
        </p>
        </>
    );
};

export { DistributedSEIR };