import React, { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { KLD_CATEGORIES_ROUTE, KLD_DATA_ENTRIE, DEMO_VERSION_ROUTE } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";

const KLD_Data_EntriesItem = ({ item, genders }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if (!item || !genders?.length)
        return;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            scrollToTop();
            history(DEMO_VERSION_ROUTE);
            return;
        }

        scrollToTop();
        history(`${KLD_CATEGORIES_ROUTE}${KLD_DATA_ENTRIE}/${item.id}?${searchParams.toString()}`);
    };

    return <div className='case_preview-item1' onClick={TryNavigateToCase} >
        {item.demo_access && user.access_level.demo_access && <div className='demo_version_tif_preview'>
            {t("Demo")}
        </div>}
        <img src={item.images_path_preview} alt="image preview" onClick={TryNavigateToCase} />
        <div className="case_preview_info1">
            <div className="case_preview_title1">
                {item['name_' + user.language]}
            </div>
        </div>
    </div>;
};

export default KLD_Data_EntriesItem;

// Пол: {record.gender === 1 ? 'мужской' : record.gender === 2 ? 'женский' : 'не указан'}