import React, {useCallback, useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/User_API";
import {Spinner} from "react-bootstrap";
import "./fonts.css";
import ReportMistakeDialog from './components/ReportMistakeDialog';
// import "./App.css";

const App = observer(() => {
    const {user} = useContext(Context);
    const [loading, setLoading] = useState(true);

    const CheckValidation = (data) => {
        if (!data)
            return false;

        user.setUser(true);
        user.setIsAuth(true);
        user.setProfile(data.user);
        user.setAccess_level(data.user.access_level);
        user.setCounters({});

        return true;
    };

    const handleKeyPress = (e) => {
        const isCtrlEnter = e.ctrlKey && e.key == 'Enter';
        const selectionText = window?.getSelection().toString();

        //  Если нажаты Ctrl + Enter и мы не в состоянии "загрузки" и пользователь выделил какой-то текст на странице...
        if (isCtrlEnter && !loading && selectionText != '')
        {
            //  TODO: показать диалог с предложением отправить сообщение о грамматической или иной ошибке.
            console.log(`Выделен текст: ${selectionText}`);
        }
    };

    useEffect(() => {
        check().then(CheckValidation).finally(() => setLoading(false));
    }, []);

    useEffect( () => {
        document.addEventListener( 'keydown', handleKeyPress );

        return () => {
            document.removeEventListener( 'keydown', handleKeyPress );
        }
    }, [ handleKeyPress ]);

    if (loading)
    {
        return <div>
            <p>Loading...</p>
            <Spinner animation={"grow"}/>
        </div>;
    }

    return <BrowserRouter forceRefresh>
        <NavBar/>
        <AppRouter/>
        <Footer/>
    </BrowserRouter>;
});

export default App;