import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Endoscopy ]

//===========
// begin [ Endoscopy_Categories ]

export const fetchEndoscopy_Categories = async (search, language) => {
    const {data} = await $host.get('api/endoscopy/endoscopy_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneEndoscopy_Categories = async (id) => {
    const {data} = await $host.get('api/endoscopy/endoscopy_categories/' + id)
    return data
}

// end [ Endoscopy_Categories ]
//===========

//-----------

//===========
// begin [ Endoscopy_Data ]

export const fetchEndoscopy_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/endoscopy/endoscopy_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOneEndoscopy_Data = async (id) => {
    const {data} = await $host.get('api/endoscopy/endoscopy_data/' + id)
    return data
}

// end [ EndoscopyData ]
//===========

//// end [ Endoscopy ]
////===========

//-----------