import React, { useState, useRef, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./ECG_Data_Con_Parameters_Table.css";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { Button, Collapse } from 'react-bootstrap'

const ECG_Data_Con_Parameters_Table = ({ data }) => {
    const { user } = useContext(Context)
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    if (data.length === 0) {
        return (<div></div>); // Возвращаем null, если нет данных для отображения
    };

    let columnDictionary

    switch (user.language) {
        case 'ru':
            columnDictionary = {
                zybci_segmenti_interval: 'зубцы, сегменты, интервалы',
                amplityda_prodolzhit: 'амплитуда, продолжительность',
                I: 'I',
                II: 'II',
                III: 'III',
                aVR: 'aVR',
                aVL: 'aVL',
                aVF: 'aVF',
                V1: 'V1',
                V2: 'V2',
                V3: 'V3',
                V4: 'V4',
                V5: 'V5',
                V6: 'V6',
            }
            break;
        case 'en':
            columnDictionary = {
                zybci_segmenti_interval: 'waves, segments, intervals',
                amplityda_prodolzhit: 'amplitude, duration',
                I: 'I',
                II: 'II',
                III: 'III',
                aVR: 'aVR',
                aVL: 'aVL',
                aVF: 'aVF',
                V1: 'V1',
                V2: 'V2',
                V3: 'V3',
                V4: 'V4',
                V5: 'V5',
                V6: 'V6',
            }
            break;
        default:
            columnDictionary = {
                zybci_segmenti_interval: 'waves RU/segments RU/intervals RU',
                amplityda_prodolzhit: 'amplitude RU/duration RU',
                I: 'I',
                II: 'II',
                III: 'III',
                aVR: 'aVR',
                aVL: 'aVL',
                aVF: 'aVF',
                V1: 'V1',
                V2: 'V2',
                V3: 'V3',
                V4: 'V4',
                V5: 'V5',
                V6: 'V6',
            }
    }

    const columnNames = Object.keys(columnDictionary);

    if (Array.isArray(data)) { // Используйте map только если data - массив
        return (
            <>
                <div className='table_collapse_button'
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                >
                    {t("Study_details")}
                    <img className='collapse_img' src={open ? '/icons/collapse_closed.svg' : '/icons/collapse_open.svg'} alt="collapse icon"></img>
                </div>
                <Collapse in={open} >
                    <div className='table_ekg_bd'>
                        <Accordion>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        {columnNames.map((columnName) => (
                                            <th key={columnName}>
                                                {columnDictionary[columnName]}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, index) => (
                                        <tr key={`row_${index}`}>
                                            {columnNames.map((columnName) => (
                                                <td key={`cell_${columnName}_${index}`}>
                                                    {row[columnName] !== null ? row[columnName] : '\u00A0'}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </Accordion>

                    </div>
                </Collapse>
            </>
        );
    }
    else { // Обработка, если data не является массивом
        return (<div></div>);
    }
};

export default ECG_Data_Con_Parameters_Table;
