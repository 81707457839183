import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
// begin [ Pimu_Categories ]

export const fetchPimu_Categories = async () => {
    const {data} = await $host.get('api/pimu_categories', )
    return data
}

export const fetchOnePimu_Categories = async (id) => {
    const {data} = await $host.get('api/pimu_categories/' + id)
    return data
}

// end [ Pimu_Categories ]
//===========

//-----------