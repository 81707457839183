import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const DefaultLanguage = 'ru';
export const LanguageKeyName = 'i18nextLng';

//  Объект (массив) с данными о локализациях.
//  Каждый элемент содержит информацию об:
//  *   иконке языка (icon),
//  *   названии языка (name),
//  *   кодовом обозначении языка (code)
//  *   связанном с ним изображении-логотипом сайта (image).
export const LanguagesData = [
    {
        icon: '/icons/ru.svg',
        image: '/icons/about/logo_rus_title_about.png',
        name: 'Русский',
        code: 'ru',
    },
    {
        icon: '/icons/en.svg',
        image: '/icons/about/logo_eng_title_about.png',
        name: 'English',
        code: 'en',
    },
];

(async () => {
    //  Инициализация объекта i18n для работы с локализацией.

    //  Сперва нужно получить сохранённое в localStorage значение с кодом языка.
    //  Если такого нет, то записать туда значение по умолчанию.
    const savedLanguage = localStorage.getItem(LanguageKeyName);
    if (!savedLanguage)
        localStorage.setItem(LanguageKeyName, DefaultLanguage);

    //  Теперь можно инициализировать объект работы с локализацией.
    await i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: savedLanguage ?? DefaultLanguage,
            debug: true,
            interpolation: {
                escapeValue: false,
            },
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
            react: {
                useSuspense: false,
            },
            initImmediate: false,
        });
}
)();

export default i18n;