import { $host } from '../index';

//-----------

//===========
//// begin [ KLD ]

//===========
// begin [ KLD_Categories ]

export const fetchKLD_Categories = async (search, language) => {
    const {data} = await $host.get('api/kld/kld_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneKLD_Categories = async (id) => {
    const {data} = await $host.get('api/kld/kld_categories/' + id)
    return data
}

// end [ KLD_Categories ]
//===========

//-----------

//===========
// begin [ KLD_Data ]

export const fetchKLD_Data = async (search, language, gender, categories_id, ages, page, limit) => {
    const {data} = await $host.get('api/kld/kld_data', {params: {
            search, language, gender, categories_id, ages, page, limit
        }})
    return data
}

export const fetchOneKLD_Data = async (id) => {
    const {data} = await $host.get('api/kld/kld_data/' + id)
    return data
}

// end [ KLD_Data ]
//===========

//// end [ KLD ]
////===========

//-----------