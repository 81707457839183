import React, {useContext, useEffect} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {fetchTestExtra_Categories} from "../../http/Data_API/TestExtra_Data_API";
import TestExtra_Categories_ButtonDisplay from "../../components/TestExtra/TestExtra_Categories_ButtonDisplay";
import {useTranslation} from "react-i18next";

const TestExtra_Categories_Page = observer(() => {
    const {record} = useContext(Context)
    const rows = [];
    const { t } = useTranslation();

    useEffect(() => {
        fetchTestExtra_Categories().then(data => record.testExtra.setTestExtra_Categories(data))
    }, [record])

    //console.log(record.testExtra.testExtra_categories)

    for (let i = 0; i < record.testExtra.testExtra_categories.length; i++)
    {
        if (i>0)
        {
            rows.push(<div key={record.testExtra.testExtra_categories[i].id + "_" + i} style={{width: "auto", height: 10}}></div>);
        }

        rows.push(<TestExtra_Categories_ButtonDisplay i={i}/>)
    }

    return (
        <div>{rows}</div>
    );
});

export default TestExtra_Categories_Page;