import React, { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ECG_CATEGORIES_ROUTE, ECG_DATA_ENTRIE, DEMO_VERSION_ROUTE } from "../../utils/consts";
import "./ECG_Data_EntriesItem.css";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";

const ECG_Data_EntriesItem = ({ item, genders }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if (!item || !genders?.length)
        return;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            scrollToTop();
            history(DEMO_VERSION_ROUTE);
            return;
        }

        scrollToTop();
        history(`${ECG_CATEGORIES_ROUTE}${ECG_DATA_ENTRIE}/${item.id}?${searchParams.toString()}`);
    };

    return <div className='ecg_data_entires_item-list' onClick={TryNavigateToCase}>
        <div className='ecg_data_entries_item-items'>
            <div className='ecg_data_entries_item_info'>
                <div className='icon_patient'>
                    <img src='/icons/medical-history-doctor-svgrepo-com.svg' />
                </div>
                <div className='ecg_data_entries_item-name'>
                    {t('case')} {item.id}
                </div>
                <hr className='ecg_data_entries_hr'></hr>
                <div className='ecg_data_entries_item-datebirth'>
                    {t('age_preview')}<div className='num_datebirth'>{item.date_of_birth}</div>
                </div>
                <div className='ecg_data_entries_item-gender'>
                    {genders.find(({ id }) => id == item.gender)?.['name_' + user.language]}
                </div>
                {item.demo_access && user.access_level.demo_access && <div className='ecg_demo'>{t('Demo')}</div>}
            </div>
        </div>
    </div>;
};

export default ECG_Data_EntriesItem;

// Пол: {record.gender === 1 ? 'мужской' : record.gender === 2 ? 'женский' : 'не указан'}