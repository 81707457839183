import React from 'react';

const TestExtra_Data_Image = ({url}) => {

    console.log(url)
    
    return (
        <div> <img src={url} alt={"изображение_ЭКГ"}/> </div>
    );
};

export default TestExtra_Data_Image;