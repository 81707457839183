import { useState, useEffect } from 'react';

import './CountdownTimer.css';

const CountdownTimer = ({ time, onTick, onEnd }) => {
    const [delay, setDelay] = useState(time);
    const minutes = Math.floor(delay / 60);
    const seconds = delay % 60;

    useEffect(() => {
        const timer = setInterval(() => {
            setDelay(prevDelay => prevDelay - 1);
            if (onTick) onTick();
        }, 1000);

        if (delay === 0) {
            clearInterval(timer);
            if (onEnd) onEnd();
        }

        return () => {
            clearInterval(timer);
        };
    }, [delay, onTick, onEnd]);

    return (
        <div className='timer'>
            <div className='minutes'>{minutes}</div>:
            <div className='seconds'>{seconds.toString().padStart(2, '0')}</div>
        </div>
    );
};

export default CountdownTimer;