import React, { useState, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import "./Footer.css"
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { ReactSVG } from 'react-svg';
import { Context } from "../index";

const Footer = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();

    //  Роль пользователя. Если её нет - то пользователь демо.
    const userRole = user.profile.role ?? 'demo';

    const currentYear = new Date().getFullYear();

    const logOut = () => {
        user.setUser({});
        user.setIsAuth(false);

        localStorage.removeItem('token');
    };
    <a href="mailto:support_atlas@pimunn.net"> support_atlas@pimunn.net</a>

    return (
        <div className="footer">
            <div className="footer_top_section">
                <a href="https://futuredoc.minzdrav.gov.ru/" target="_blank" rel="noopener noreferrer">
                    <img className="icon_footer" src="/icons/futuredoc.png" alt="futuredoc" />
                </a>
                <img className="icon_footer" src="/icons/about/logo_rus_title_about.png" alt="icon_footer" />
            </div>
            {/* <div className="footer_medatlas_title">{t('start_page_title')}</div> */}
            <div className="footer_grid">
                <div className="menu_block_footer">
                    <div className='footer_title'>
                        {t('footer_title_option')}
                    </div>
                    <ul className="list-unstyled">
                        <li>
                            <a href="/pimu" className="footer_text">
                                {t('footer_title_menu')}
                            </a>
                        </li>
                        <li>
                            <a href="/" className="footer_text">
                                {t('navbar_title_main')}
                            </a>
                        </li>
                        {user.isAuth && userRole != 'demo' ? <>
                            <li><a href="/account" className="footer_text">{t('navbar_title_account')}</a></li>
                            <li><a href='/login' onClick={() => logOut()} className='footer_text'>{t('navbar_title_authorization_logout')}</a></li>
                        </> :
                            <li><a href='/login' className='footer_text'>{t('navbar_title_authorization_login')}</a></li>
                        }
                    </ul>
                </div>
                <div className='documentation_block_footer'>
                    <div className='footer_title'>
                        {t('footer_title_option_documentation')}
                    </div>
                    <ul className="list-unstyled">
                        <li>
                            <a href="/about_application" className="footer_text">
                                {t("navbar_title_about")}
                            </a>
                        </li>
                        <li>
                            <a href="/terms" className="footer_text">
                                {t("footer_title_terms")}
                            </a>
                        </li>
                        <li>
                            <a href="/system_requirements" className="footer_text">
                                {t("System requirements")}
                            </a>
                        </li>
                        <li>
                            <a href="/support" className="footer_text">
                                {t("navbar_title_support")}
                            </a>
                        </li>
                        <li>
                            <a href="/agreement" className="footer_text">
                                {t("navbar_title_agreement")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='contacts_block_footer'>
                    <div className='footer_title'>
                        {t('footer_title_option_contacts')}
                    </div>
                    <ul className="list-unstyled">
                        <li>
                            <a href="mailto:sale@pimunn.net" className="footer_text">
                                {t('footer_title_option_mail')}
                            </a>
                        </li>
                        <li></li>
                    </ul>
                </div>
                <div className='subscribe_block_footer'>
                    <div className='footer_title'>
                        {t('footer_title_option_subscribe')}
                    </div>
                    <ul className="list-unstyled">
                        <li>
                            <a href="/subscribe" className="footer_text">
                                {t("navbar_title_subsribe")}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className='hr_copyright'></hr>
            <div className="d-flex justify-content-center">
                <div className="copyright">
                    <p>
                        {t('start_page_title')} © {t('footer_title_copyright')} {currentYear}
                    </p>
                </div>
            </div>
        </div >
    );
});

export default Footer;
