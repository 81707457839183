import React from 'react';
import Endoscopy_Data_Image from "./Endoscopy_Data_Image";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "./Endoscopy_Data_Images_Display.css"


const Endoscopy_Data_Images_Display = ({ imageurl }) => {
    const imageurldef = "/";

    const handleContextMenu = (e) => {
        e.preventDefault(); // Отключаем контекстное меню
    };

    return (
        <div className="case_images" onContextMenu={handleContextMenu} key={"image_name"} >
            {imageurl.map((url, i) => (
                <Zoom key={i}>
                    <Endoscopy_Data_Image url={imageurldef + url} />
                </Zoom>
            ))}
        </div>
    );
};

export default Endoscopy_Data_Images_Display;