import { $host } from '../index';

//-----------

//===========
//// begin [ Microbiology ]

//===========
// begin [ Microbiology_Categories ]

export const fetchMicrobiology_Categories = async (search, language) => {
    const {data} = await $host.get('api/microbiology/microbiology_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneMicrobiology_Categories = async (id) => {
    const {data} = await $host.get('api/microbiology/microbiology_categories/' + id)
    return data
}

// end [ Microbiology_Categories ]
//===========

//-----------

//===========
// begin [ Microbiology_Data ]

export const fetchMicrobiology_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/microbiology/microbiology_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOneMicrobiology_Data = async (id) => {
    const {data} = await $host.get('api/microbiology/microbiology_data/' + id)
    return data
}

// end [ Microbiology_Data ]
//===========

//// end [ Microbiology ]
////===========

//-----------