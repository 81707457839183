import DZI_ImageViewer_Quiz from './DZI_ImageViewer_Quiz';
import DZI_ProtectedImageViewer from './DZI_ProtectedImageViewer';
import "./DZI_ImageViewer.css"

function DZI_Images_Display_Quiz({ images }) {
    if (!images)
        return;

    //return <DZI_ProtectedImageViewer images={images} />;
    return <DZI_ImageViewer_Quiz images={images} />;
}

export default DZI_Images_Display_Quiz;
