import { observer } from "mobx-react-lite";
import Patanatomy_Data_EntriesItem from "./Patanatomy_Data_EntriesItem";
import "./Dzi_Data_EntriesList.css"

const Patanatomy_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='dzi_items_flex'>
        {pageData.items.rows.map((item) => <Patanatomy_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Patanatomy_Data_EntriesList;