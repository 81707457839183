import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { Spinner } from 'react-bootstrap';

import './ReportMistakeDialog.css';

const ReportMistakeDialog = observer( () => {

    return <div className='dialog_background'>
        <div className='dialog_report_mistake'>
            <div className='dialog_header'>
                <span className='large'>Сообщить об ошибке</span>
                <br />
                <span className='small'>Введите текст ошибки, а также какую-либо дополнительную информацию в поле ниже.</span>
            </div>
            <div className='dialog_body'>
                <textarea></textarea>
            </div>
            <div className='dialog_footer'>
                <button className='button primary'>отправить</button>
                <button className='button secondary'>отмена</button>
            </div>
        </div>
    </div>;
});

export default ReportMistakeDialog;