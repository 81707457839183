import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./Demo_Version_Page.css"
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from "react-i18next";
import Spline from '@splinetool/react-spline';

const Demo_Version_Page = observer(() => {
    const history = useNavigate()

    const [textIndex, setTextIndex] = useState(0);
    const { t } = useTranslation();

    const texts = t('demo_page_animated_text', { returnObjects: true });
    const variants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    return (
        <div className='demo_page'>
            <div className='demo_page_grid'>
                <div className='demo_page_grid_first_section'>
                    <div className='demo_link'>
                        <b>{t('start_page_title')}</b> • {t('buy')}
                        {/* {t('demo_buy')} */}
                    </div>
                    <div className='demo_page_description'>{t('demo_page_description')}</div>
                    <div className='ekg3d_icon'>
                        <div className='spline_scene_demo'>
                            <Spline scene="https://prod.spline.design/UppECSZ5evF46ZQJ/scene.splinecode" />
                        </div>
                        {/* <img src='/icons/ekg_3d.png' className="ekg3d" alt='ekg3d'></img> */}
                    </div>
                </div>
                <div className='demo_page_animated_text'>
                    <br></br>
                    <br></br>
                    <br></br>
                    <AnimatePresence mode='wait'>
                        <motion.div className="animated_text"
                            key={texts[textIndex]}
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 1 }}
                            onAnimationComplete={() => {
                                setTimeout(() => {
                                    setTextIndex((textIndex + 1) % texts.length);
                                }, 3500); // Задержка перед переключением на следующий текст
                            }}
                        >
                            {texts[textIndex]}
                        </motion.div>
                    </AnimatePresence>
                    <a href="mailto:sale@pimunn.net">
                        <button className='demo_page_button_buy' to='pimu'>
                            {t('demo_page_button_buy')}
                        </button>
                    </a>
                    <div className='demo_page_email'>
                        {t('demo_page_email')} <b>sale@pimunn.net</b>
                        <p></p>
                        <p>{t('or mobile phone')} <b>+79200021155</b></p>
                    </div>
                </div>

            </div>
        </div>
    );
});

export default Demo_Version_Page;