import React, { useRef, useEffect } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from "mobx-react-lite";
import { MapContainer, TileLayer, useMap, Marker, Popup, Tooltip } from 'react-leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import ReactSlider from 'react-slider';
import { IoPlaySharp, IoPause } from 'react-icons/io5';
import { BsQuestionLg } from "react-icons/bs"
import { PiGraph } from 'react-icons/pi';
import { LuLayers } from 'react-icons/lu';
import { IoCloseSharp } from 'react-icons/io5';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Label, Legend, ReferenceLine, ReferenceDot } from 'recharts';
import * as Help from './Epidemics_Help.jsx'
import epidemic_data from './distributed_SEIR_data.json';
import "leaflet/dist/leaflet.css";
import './Epidemics_Map_Page.css';

class UI {
    toolbar_window = ""

    constructor() {
        makeObservable(this, {
            toolbar_window: observable,
            openHelpWindow: action,
            openModelSelectionWindow: action,
            openLayersWindow: action,
            closeToolbarWindows: action
        })
    }

    openHelpWindow() {
        this.toolbar_window = "Help";
    }

    openModelSelectionWindow() {
        this.toolbar_window = "ModelSelection";
    }

    openLayersWindow() {
        this.toolbar_window = "Layers";
    }

    closeToolbarWindows() {
        this.toolbar_window = "";
    }
}

class Animation {
    playing = false;
    timer = false;
    layer = 0;
    frame = 1;
    maxFrame = 0;

    constructor() {
        makeObservable(this, {
            playing: observable,
            timer: observable,
            layer: observable,
            setLayer: action,
            frame: observable,
            setFrame: action,
            maxFrame: observable
        })
    }

    setLayer = (layer) => this.layer = layer;
    setFrame = (frame) => this.frame = frame;
}

const animation = new Animation();
const ui = new UI();

const FrameSlider = observer(() => {
    const map = useMap();
    const pan_disable = useRef();

    useEffect(() => {
        document.querySelector(".выбор_кадра_слайдер_ползунок").setAttribute("title", "День эпидемии");
        document.querySelector(".выбор_кадра_слайдер_ползунок").setAttribute("aria-label", "День эпидемии");
        return () => {};
    }, []);

    useEffect(() => {
        pan_disable.current.addEventListener("mouseenter", () => map.dragging.disable());
        pan_disable.current.addEventListener("mouseleave", () => map.dragging.enable());
        return () => {};
    }, [pan_disable]);

    function animation_play_pause() {
        if (animation.playing == false) {
            if (animation.frame == animation.maxFrame) animation.setFrame(1);
            animation.playing = true;
            animation.timer = setInterval(() => animation.setFrame(animation.frame + 1), 66);
        }
        else {
            animation.playing = false;
            clearInterval(animation.timer);
        }
    }

    if (animation.frame == animation.maxFrame) {
        animation.playing = false;
        clearInterval(animation.timer);
    }

    return (
        <div className="leaflet-top leaflet-left" id="выбор_кадра_рамка_1">
            <div className="leaflet-control leaflet-bar" id="выбор_кадра_рамка_2" ref={pan_disable}>
                <div id="выбор_кадра_таблица"><div id="выбор_кадра_строка">
                    <div id="выбор_кадра_ячейка_кнопки" role="button" onClick={animation_play_pause} title={animation.playing ? "Пауза" : "Воспроизведение"} aria-label={animation.playing ? "Пауза" : "Воспроизведение"}>
                    {animation.playing ? <IoPause size={18} className="выбор_кадра_кнопка" /> : <IoPlaySharp size={18} className="выбор_кадра_кнопка" />}</div>
                    <div id="выбор_кадра_ячейка_слайдера">
                        <ReactSlider
                            className="выбор_кадра_слайдер"
                            min={1}
                            max={animation.maxFrame}
                            thumbClassName="выбор_кадра_слайдер_ползунок"
                            trackClassName="выбор_кадра_слайдер_дорожка"
                            value={animation.frame}
                            onChange={(value) => {animation.setFrame(value);}}
                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>} />
                    </div>
                </div></div>
            </div>
        </div>
    );
});

const Toolbar = observer(() => {
    const map = useMap();
    const pan_disable = useRef();

    useEffect(() => {
        pan_disable.current.addEventListener("mouseenter", () => map.dragging.disable());
        pan_disable.current.addEventListener("mouseleave", () => map.dragging.enable());
        return () => {};
    }, [pan_disable]);

    return (
        <div className="leaflet-top leaflet-left" id="панель_кнопок" ref={pan_disable}>
            <div className={"leaflet-control leaflet-bar панель_кнопок_ячейка" + (ui.toolbar_window == "Help" ? " панель_кнопок_ячейка_нажата" : "" )} id="панель_кнопок_ячейка_справочная_информация" role="button" onClick={() => {ui.openHelpWindow();}} title="Справочная информация" aria-label="Справочная информация">
                <BsQuestionLg className="панель_кнопок_кнопка" />
            </div>
            <div className={"leaflet-control leaflet-bar панель_кнопок_ячейка" + (ui.toolbar_window == "ModelSelection" ? " панель_кнопок_ячейка_нажата" : "" )} id="панель_кнопок_ячейка_выбор_модели" role="button" onClick={() => {ui.openModelSelectionWindow();}} title="Выбор модели" aria-label="Выбор модели">
                <PiGraph className="панель_кнопок_кнопка" />
            </div>
            <div className={"leaflet-control leaflet-bar панель_кнопок_ячейка" + (ui.toolbar_window == "Layers" ? " панель_кнопок_ячейка_нажата" : "" )} id="панель_кнопок_ячейка_слои" role="button" onClick={() => {ui.openLayersWindow();}} title="Слои" aria-label="Слои">
                <LuLayers className="панель_кнопок_кнопка" />
            </div>
        </div>
    );
});

const HelpWindow = observer(() => {
    const map = useMap();
    const pan_disable = useRef();

    useEffect(() => {
        pan_disable.current.addEventListener("mouseenter", () => map.dragging.disable());
        pan_disable.current.addEventListener("mouseleave", () => map.dragging.enable());
        return () => {};
    }, [pan_disable]);

    return (
        <>
        <div className="leaflet-top leaflet-left" id="окно_справочная_информация">
            <div className="leaflet-control leaflet-bar окно_ячейка окно_ячейка_справочная_информация" ref={pan_disable}>
                <Container fluid>
                    <Row className="заголовок_окна">
                        <Col md="auto">Справочная информация</Col>
                        <Col className="d-flex justify-content-end"><IoCloseSharp className="заголовок_окна_кнопка" role="button" onClick={() => {ui.closeToolbarWindows();}} /></Col>
                    </Row>
                </Container>
                <Container fluid className="основная_часть_окна">
                    <Help.DistributedSEIR />
                </Container>
            </div>
        </div>
        </>
    )
});

const ModelSelectionWindow = observer(() => {
    const map = useMap();
    const pan_disable = useRef();

    useEffect(() => {
        pan_disable.current.addEventListener("mouseenter", () => map.dragging.disable());
        pan_disable.current.addEventListener("mouseleave", () => map.dragging.enable());
        return () => {};
    }, [pan_disable]);

    return (
        <>
        <div className="leaflet-top leaflet-left" id="окно_выбор_модели">
            <div className="leaflet-control leaflet-bar окно_ячейка окно_ячейка_выбор_модели" ref={pan_disable}>
                <Container fluid>
                    <Row className="заголовок_окна">
                        <Col md="auto">Выбор модели</Col>
                        <Col className="d-flex justify-content-end"><IoCloseSharp className="заголовок_окна_кнопка" role="button" onClick={() => {ui.closeToolbarWindows();}} /></Col>
                    </Row>
                </Container>
                <Container fluid className="основная_часть_окна">
                    <Row><Col>
                        <Form.Select title="Сохраненный или новый расчет">
                            <option>Пример: Кстово</option>
                        </Form.Select>
                    </Col></Row>
                    <Row><Col>
                        <Form.Label>Разновидность модели</Form.Label>                
                        <Form.Select title="Разновидность модели">
                            <option>Распределенная SEIR-модель</option>
                        </Form.Select>
                    </Col></Row>

                    <Row><Col>
                        <Form.Label>Город</Form.Label>
                        <Form.Select title="Город">
                            <option>Кстово</option>
                        </Form.Select>
                    </Col></Row>

                    <Row>
                        <Col>
                            <Form.Label>Индекс репродукции R<sub>0</sub></Form.Label>
                            <Form.Control type="number" title="Индекс репродукции (в начале эпидемии)" />
                        </Col>
                        <Col>
                            <Form.Label>&rarr; Коэф-т &beta; = </Form.Label>
                            <Form.Control type="number" title="Коэффициент скорости инфицирования контактных лиц" value={epidemic_data["параметры_модели"]["dSEIR_beta"]} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Инкубационный период</Form.Label>
                            <Form.Control type="number" title="Средняя длительность инкубационного периода" placeholder="(дней)" />
                        </Col>
                        <Col>
                            <Form.Label>&rarr; Коэф-т &sigma; = </Form.Label>
                            <Form.Control type="number" title="Коэффициент скорости выздоровления" value={epidemic_data["параметры_модели"]["dSEIR_sigma"]} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Длительность заболевания</Form.Label>
                            <Form.Control type="number" title="Средняя длительность активной фазы заболевания" placeholder="(дней)" />
                        </Col>
                        <Col>
                            <Form.Label>&rarr; Коэф-т &gamma; = </Form.Label>
                            <Form.Control type="number" title="Коэффициент скорости перехода в активную фазу заболевания" value={epidemic_data["параметры_модели"]["dSEIR_gamma"]} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Радиус инфицирования</Form.Label>
                            <Form.Control type="number" title="Средний радиус зоны, в которой перемещается инфицированный" placeholder="(км)" />
                        </Col>
                        <Col className="d-grid gap-2">
                            <Form.Label>Расчет модели недоступен</Form.Label>
                            <Button disabled={true} variant="success" className="основная_часть_окна_кнопка">Рассчитать</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        </>
    );
})

const LayersWindow = observer(() => {
    const map = useMap();
    const pan_disable = useRef();

    useEffect(() => {
        pan_disable.current.addEventListener("mouseenter", () => map.dragging.disable());
        pan_disable.current.addEventListener("mouseleave", () => map.dragging.enable());
        return () => {};
    }, [pan_disable]);

    const layers_radiolist = epidemic_data["порядок_слоев"].map((layer, i) => 
        <Form.Check>
            <Form.Check.Input type="radio" name="layers" id={("layers" + i)} value={i} checked={animation.layer == i} onClick={() => animation.setLayer(i)} />
            <Form.Check.Label for={("layers" + i)} style={{color: epidemic_data["параметры_слоев"][layer]["цвет_линии"]}} onClick={() => animation.setLayer(i)}>{epidemic_data["параметры_слоев"][layer]["описание"]}</Form.Check.Label>
        </Form.Check>);

    return (
        <>
        <div className="leaflet-top leaflet-left" id="окно_слои">
            <div className="leaflet-control leaflet-bar окно_ячейка окно_ячейка_слои" ref={pan_disable}>
                <Container fluid>
                    <Row className="заголовок_окна">
                        <Col md="auto">Слои</Col>
                        <Col className="d-flex justify-content-end"><IoCloseSharp className="заголовок_окна_кнопка" role="button" onClick={() => {ui.closeToolbarWindows();}} /></Col>
                    </Row>
                </Container>
                <Container fluid className="основная_часть_окна">
                    {layers_radiolist}
                </Container>
                <div className='invisible'>{animation.layer}</div>
            </div>
        </div>
        </>
    );
});

const SEIRGraph = observer(() => {
    const map = useMap();
    const pan_disable = useRef();

    useEffect(() => {
        pan_disable.current.addEventListener("mouseenter", () => map.dragging.disable());
        pan_disable.current.addEventListener("mouseleave", () => map.dragging.enable());
        return () => {};
    }, [pan_disable]);

    const chart_lines = epidemic_data["порядок_слоев"].map((layer) => 
        <Line type="monotone" isAnimationActive={false} dataKey={layer} stroke={epidemic_data["параметры_слоев"][layer]["цвет_линии"]} strokeDasharray={(layer == epidemic_data["порядок_слоев"][animation.layer] ? "" : "1 1")} strokeWidth={(layer == epidemic_data["порядок_слоев"][animation.layer] ? 2 : 1)} dot={false} />);

    return (
        <>
            <div className="leaflet-top leaflet-right" id="график">
                <div className="leaflet-control leaflet-bar" id="график_ячейка" ref={pan_disable}>
                    <LineChart width={400} height={300} data={epidemic_data["динамика"]} margin={{top: 12, right: 28, left: 0, bottom: 20}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" dataKey="x" scale="linear" interval={13} domain={[1, animation.maxFrame]}><Label dy={15} value="День эпидемии" /></XAxis>
                        <YAxis domain={[0, 1]} tickCount={6}><Label dx={-8} angle={270} value="Доля населения" /></YAxis>
                        <Legend width={396} wrapperStyle={{marginTop: "-6px"}} align="center" verticalAlign="top" />
                        {chart_lines}
                        <ReferenceLine stroke={epidemic_data["параметры_слоев"][epidemic_data["порядок_слоев"][animation.layer]]["цвет_линии"]} segment={[{"x": animation.frame, "y": 0}, {"x": animation.frame, "y": epidemic_data["динамика"][animation.frame-1][epidemic_data["порядок_слоев"][animation.layer]]}]} />
                        <ReferenceDot x={animation.frame} y={epidemic_data["динамика"][animation.frame-1][epidemic_data["порядок_слоев"][animation.layer]]} r={3} stroke={epidemic_data["параметры_слоев"][epidemic_data["порядок_слоев"][animation.layer]]["цвет_линии"]} strokeWidth={2} fill="#ffffff" isFront={true} />
                    </LineChart>
                </div>
            </div>
        </>
    );    
});

const Epidemics_Map_Page = observer(() => {
    useEffect(() => {
        animation.maxFrame = epidemic_data["динамика_по_точкам"][0][2][0].length;
        animation.setLayer(epidemic_data["порядок_слоев"].indexOf(epidemic_data["параметры_модели"]["default_layer"]));
        document.querySelector(".leaflet-control-zoom-in").setAttribute("title", "Увеличить масштаб");
        document.querySelector(".leaflet-control-zoom-in").setAttribute("aria-label", "Увеличить масштаб");
        document.querySelector(".leaflet-control-zoom-out").setAttribute("title", "Уменьшить масштаб");
        document.querySelector(".leaflet-control-zoom-out").setAttribute("aria-label", "Уменьшить масштаб");
        return () => {};
    }, []);

    return (
        <>
        <MapContainer center={[56.1473, 44.1979]} zoom={13} scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={epidemic_data["динамика_по_точкам"]}
            longitudeExtractor={m => m[1]}
            latitudeExtractor={m => m[0]}
            intensityExtractor={m => parseFloat(m[2][animation.layer][animation.frame-1])}
            max={1.0}
            useLocalExtrema={false}
            radius={10}
            opacity={0.7} />
        <FrameSlider />
        <Toolbar />
        {ui.toolbar_window == "Help" && <HelpWindow /> }
        {ui.toolbar_window == "ModelSelection" && <ModelSelectionWindow /> }
        {ui.toolbar_window == "Layers" && <LayersWindow /> }
        <SEIRGraph />
        <div className='invisible'>{animation.layer}{animation.frame}</div>
        </MapContainer>
        </>
    );

});

export default Epidemics_Map_Page;