import { observer } from "mobx-react-lite";
import Microbiology_Data_EntriesItem from "./Microbiology_Data_EntriesItem";
import { fetchGender } from "../../http/Data_API/Gender_Data_API";
import { useState, useEffect } from "react";

const Microbiology_Data_EntriesList = observer(({ pageData }) => {
    const [genders, setGenders] = useState(null);

    useEffect(() => {
        fetchGender().then((genders) => setGenders(genders));
    }, [pageData]);

    if (!pageData?.items?.rows || !genders)
        return;

    return <div className="ecg_list_flex">
        {pageData.items.rows.map((item) => <Microbiology_Data_EntriesItem key={item.id} item={item} genders={genders} />)}
    </div>;
});

export default Microbiology_Data_EntriesList;