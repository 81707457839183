import {$host} from '../index';

//-----------

//===========
// begin [ Protected ]

export const fetchOneProtected_Data = async (id) => {
    if (!id)
        return null;

    const { data } = await $host.get('api/protected/' + encodeURIComponent(btoa(id)) + '/' + localStorage.getItem('token'));
    return data;
}

// end [ Protected ]
//===========

//-----------