import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import "./AgeBar.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const DefaultMaxAge = 100;
const DefaultMinAge = 0;
const AgeStep = 10;

const Age_Bar = observer(({ onAgeChanged }) => {
    const { t } = useTranslation()
    const { user } = useContext(Context);
    const location = useLocation();
    const history = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [selectedAges, setSelectedAges] = useState(searchParams.has('ages') ? decodeURIComponent(searchParams.get('ages')).split(',') : [DefaultMinAge, DefaultMaxAge]);


    const HandleAgeChange = (event) => {
        const [min, max] = event;

        setSelectedAges([min, max]);
        onAgeChanged({ min, max });

        searchParams.set('ages', [min, max].join(','));
        history(`?${searchParams.toString()}`);
    };

    return <div className='ages_list'>
        <div className='ages_list_title'>
            {t("select_age")}
        </div>
        <div className='ages_list_slider'>
            <Slider trackStyle={{ backgroundColor: "lightgrey", height: 5 }}
                railStyle={{ backgroundColor: "#f4f4f4", height: 5 }}
                handleStyle={{
                    border: "none",
                    boxShadow: "none",
                    height: 17,
                    width: 17,
                    marginTop: -6,
                    background: "#00e887",
                    opacity: 1,
                }} className='Slider_age' range step={AgeStep} min={DefaultMinAge} max={DefaultMaxAge} allowCross={false} defaultValue={selectedAges} onChange={HandleAgeChange} />
            <div className='ages_list_slider_numbers'>{selectedAges[0]} - {selectedAges[1]}</div>
        </div>
    </div>;
});


export default Age_Bar;
