import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import './Subscribe_Page.css';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const AnimatedBlock = ({ children, delay = 0, className }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay }}
            className={className}
        >
            {children}
        </motion.div>
    );
};



const Subscribe_Page = observer(() => {
    const history = useNavigate();
    const { t } = useTranslation();


    return <>
        <div className='subscribe_page'>
            <div className='subscribe_grid'>
                <AnimatedBlock className='subscribe_title'>{t("navbar_title_subsribe")}</AnimatedBlock>
                <AnimatedBlock delay={0.2} className='subscribe_desc'>{t('sub_info')}</AnimatedBlock>
                <AnimatedBlock delay={0.3} className='subscribe_category_university'>{t('university')}</AnimatedBlock>

                <hr className='subscribe_category_hr_university'></hr>

                <AnimatedBlock delay={0.3} className='subscribe_category_info_batch_university'>{t('subscription names')}</AnimatedBlock>
                <div className='subscribe_batchs_university'>
                    <AnimatedBlock delay={0.7} className='batch'>
                        <div className='batch_name'>
                            <img src='/icons/subscribe/basic_icon.svg'></img>{t('basic')}</div>
                        <div className='batch_prices'>
                            <div className='batch_rub_symbol'>₽</div><div className='batch_price'>900.000</div><div className='batch_price_year'>/ {t('num_year_1')}</div>
                        </div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t("usage period")} 1 {t('num_year_1')}</div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t('up500')}</div>
                        <a className='buy_batch_a' href="mailto:sale@pimunn.net">
                            <button className='batch_button_buy' to='pimu'>
                                {t('buy')}
                            </button>
                        </a>
                    </AnimatedBlock>
                    <AnimatedBlock delay={1} className='batch'>
                        <div className='batch_name'>
                            <img src='/icons/subscribe/optimum_icon.svg'></img>{t('opt')}</div>
                        <div className='batch_prices'>
                            <div className='batch_rub_symbol'>₽</div><div className='batch_price'>1.900.000</div><div className='batch_price_year'>/ {t('num_year_1')}</div>
                        </div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t("usage period")} 1 {t('num_year_1')}</div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t('from500')}</div>
                        <a className='buy_batch_a' href="mailto:sale@pimunn.net">
                            <button className='batch_button_buy' to='pimu'>
                                {t('buy')}
                            </button>
                        </a>
                    </AnimatedBlock>
                    <AnimatedBlock delay={1.3} className='batch'>
                        <div className='batch_name'>
                            <img src='/icons/subscribe/premium_icon.svg'></img>{t('premium')}</div>
                        <div className='batch_prices'>
                            <div className='batch_rub_symbol'>₽</div><div className='batch_price'>5.000.000</div>
                        </div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t("usage period")} 10 {t('num_year_5')}</div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t('nolimit')}</div>
                        <a className='buy_batch_a' href="mailto:sale@pimunn.net">
                            <button className='batch_button_buy' to='pimu'>
                                {t('buy')}
                            </button>
                        </a>
                    </AnimatedBlock>
                </div>
                <AnimatedBlock delay={0.3} className='subscribe_category_college'>{t('college')}</AnimatedBlock>
                <hr className='subscribe_category_hr_college'></hr>
                <AnimatedBlock delay={0.3} className='subscribe_category_info_batch_college'>{t('subscription names')}</AnimatedBlock>
                <div className='subscribe_batchs_college'>
                    <AnimatedBlock delay={0.7} className='batch'>
                        <div className='batch_name'>
                            <img src='/icons/subscribe/basic_icon.svg'></img>{t('basic')}</div>
                        <div className='batch_prices'>
                            <div className='batch_rub_symbol'>₽</div><div className='batch_price'>630.000</div><div className='batch_price_year'>/ {t('num_year_1')}</div>
                        </div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t("usage period")} 1 {t('num_year_1')}</div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t('up500')}</div>
                        <a className='buy_batch_a' href="mailto:sale@pimunn.net">
                            <button className='batch_button_buy' to='pimu'>
                                {t('buy')}
                            </button>
                        </a>
                    </AnimatedBlock>
                    <AnimatedBlock delay={1} className='batch'>
                        <div className='batch_name'>
                            <img src='/icons/subscribe/optimum_icon.svg'></img>{t('opt')}</div>
                        <div className='batch_prices'>
                            <div className='batch_rub_symbol'>₽</div><div className='batch_price'>1.330.000</div><div className='batch_price_year'>/ {t('num_year_1')}</div>
                        </div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t("usage period")} 1 {t('num_year_1')}</div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t('from500')}</div>
                        <a className='buy_batch_a' href="mailto:sale@pimunn.net">
                            <button className='batch_button_buy' to='pimu'>
                                {t('buy')}
                            </button>
                        </a>
                    </AnimatedBlock>
                    <AnimatedBlock delay={1.3} className='batch'>
                        <div className='batch_name'>
                            <img src='/icons/subscribe/premium_icon.svg'></img>{t('premium')}</div>
                        <div className='batch_prices'>
                            <div className='batch_rub_symbol'>₽</div><div className='batch_price'>3.250.000</div>
                        </div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t("usage period")} 10 {t('num_year_5')}</div>
                        <div className='batch_exp'>
                            <img src='/icons/subscribe/done.png'></img>{t('nolimit')}</div>
                        <a className='buy_batch_a' href="mailto:sale@pimunn.net">
                            <button className='batch_button_buy' to='pimu'>
                                {t('buy')}
                            </button>
                        </a>
                    </AnimatedBlock>
                </div>
            </div>
        </div>
    </>;
});

export default Subscribe_Page;