/* eslint-disable no-restricted-globals */

import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";
import { fetchTesting_Categories, fetchTesting_Category_Tests, recordUserQuiz, fetchTesting_Counters, fetchTesting_UserCounters } from '../../http/Data_API/Testing_Data_API';
import QuizComponent from '../../components/Quiz';
import './TestingPage.css';
import { ClenseNumeral } from '../../utils/PageDataPrepare';
import CountdownTimer from '../../components/Countdown/CountdownTimer';

//  Количество вопросов, которые нужно отображать для каждого из режимов.
//  Для режима "тестирование".
const QuestionsForTestingMode = 20;
//  Для режима "тренировка".
const QuestionsForTrainingMode = null;

//  Ограничение по времени на прохождение теста (в секундах).
const TimeLimitForTestingMode = 20 * 60; // кол-во секунд = 20 мин. * 60 сек.

const TestingTypeBlock = ({ t, setTestingState, testingState }) => {

    useState({
        mode: null,
    });

    const ChooseTestingType = (t) => {
        setTestingState({
            ...testingState,
            mode: t,
        });
    };

    return <div className='tp_block_sidebar'>
        <div className='tp_block_sidebar_title'>
            <div className='tp_block_stats_active'>{t('quiz_choose_type')}</div>
            <hr className='tp_block_hr'></hr>
        </div>

        <div className='tp_block_sidebar_menu'>
            <div
                className={`tp_block_sidebar_selected ${testingState.mode === 'training' ? 'active' : ''}`}
                onClick={(e) => ChooseTestingType('training')}
            >
                <div className='tp_block_sidebar_selected_name'>{t('quiz_type_training')}</div>
                <div className='tp_block_sidebar_selected_description'>{t('quiz_type_training_desc')}</div>
            </div>
            <div
                className={`tp_block_sidebar_selected ${testingState.mode === 'testing' ? 'active' : ''}`}
                onClick={(e) => ChooseTestingType('testing')}
            >
                <div className='tp_block_sidebar_selected_name'>{t('quiz_type_testing')}</div>
                <div className='tp_block_sidebar_selected_description'>{t('quiz_type_testing_desc')}</div>
            </div>
        </div>
    </div>;
};

const AchievementsInfoBlock = ({ t, achievements }) => {
    return <div className='tp_block_achievments'>
        <div className='tp_block_sidebar_title'>
            <div className='tp_block_achievments_title'>Система достижений атласа</div>
        </div>
        <div className='tp_block_sidebar_menu'>
            <div className='tp_block_achievements_desc'>
                За определенное колличество решенных задач
                вы можете заработать достижения, например <b>медали</b>
                <br></br>
                <br></br>
                Они отображаются в профиле и видны всем другим участникам.
                <br></br>
                <br></br>
                Чем больше решенных задач - тем выше ранг.
                <br></br>
                <br></br>
                Наивысший ранг показывает абсолютное мастерство в решении задач.
            </div>
            <div className='tp_block_achievements_medals'>
                <div className='tp_block_achievement'>
                    <img src='icons/achievements/gold_medal.png' className='tp_block_achievement_icon'></img>
                    <div className='tp_block_achievement_title'>Мастер
                        <div className='tp_block_achievement_nums'>300 задач</div>
                    </div>
                </div>
                <hr className='tp_block_hr'></hr>
                <div className='tp_block_achievement'>
                    <img src='icons/achievements/silver_medal.png' className='tp_block_achievement_icon'></img>
                    <div className='tp_block_achievement_title'>Мидл
                        <div className='tp_block_achievement_nums'>200 задач</div>
                    </div>
                </div>
                <hr className='tp_block_hr'></hr>
                <div className='tp_block_achievement'>
                    <img src='icons/achievements/bronze_medal.png' className='tp_block_achievement_icon'></img>
                    <div className='tp_block_achievement_title'>Интерн
                        <div className='tp_block_achievement_nums'>100 задач</div>
                    </div>
                </div>
                <hr className='tp_block_hr'></hr>
                <div className='tp_block_achievement'>
                    <img src='icons/achievements/newbie_medal.png' className='tp_block_achievement_icon'></img>
                    <div className='tp_block_achievement_title'>Новичок
                        <div className='tp_block_achievement_nums'>25 задач</div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

const UserInfoBlock = ({ t, user }) => {
    const DateDifferenceString = (date, showTime = false) => {
        if (!date)
            return;

        if (showTime && (date.years == 0 && date.months == 0 && date.days == 0 && date.hours == 0 && date.minutes < 5))
            return <>меньше 5 минут</>;
        else
            return <>
                {date.years > 0 ? `${date.years} ${ClenseNumeral(date.years, [t('num_year_1'), t('num_year_2'), t('num_year_5')])} ` : ``}
                {date.months > 0 ? `${date.months} ${ClenseNumeral(date.months, [t('num_month_1'), t('num_month_2'), t('num_month_5')])} ` : ``}
                {date.days > 0 ? `${date.days} ${ClenseNumeral(date.days, [t('num_day_1'), t('num_day_2'), t('num_day_5')])} ` : ``}
                {showTime && date.hours > 0 ? `${date.hours} ${ClenseNumeral(date.hours, [t('num_hour_1'), t('num_hour_2'), t('num_hour_5')])} ` : ``}
                {showTime && date.minutes > 0 ? `${date.minutes} ${ClenseNumeral(date.minutes, [t('num_minute_1'), t('num_minute_2'), t('num_minute_5')])}` : ``}
            </>;
    };

    return <div className='tp_block_user_header'>
        <img src='icons/no_photo.png' className='tp_block_user_header_photo' />
        <div className='tp_block_user_header_info'>
            <div className='tp_block_user_header_name'>
                {user.profile?.profile?.firstName && user.profile?.profile?.lastName ?
                    <>
                        {user.profile?.profile?.firstName} {user.profile.profile?.lastName}
                    </> : <>
                        {user.profile?.email}
                    </>
                }
            </div>
            <div className='tp_block_user_header_faculty'>{user.profile?.profile?.faculty}</div>
            <hr className='tp_block_hr'></hr>
            <div className='tp_block_user_header_exp'>{t('member_for_days')} {DateDifferenceString(user.profile.createdAgo)}</div>
            {
                //<div className='tp_block_user_header_last_session'>последнее посещение <b>{DateDifferenceString(user.profile.updatedAgo, true)}</b> назад</div>
            }
            <div className='tp_block_user_header_tasks'>
                <img className='tp_block_user_header_tasks_medal' />
                <div className='tp_block_user_header_tasks_nums'>
                    {user?.counters?.total_passed > 0 && `${t('quiz_tasks_completed_overall')} - ${user.counters.total_passed}`}
                </div>
            </div>
            {/* <button className='tp_block_user_header_follow'>
            Подписаться
        </button> */}
        </div>
    </div>;
};

//  Блок с выбором раздела тестирования.
const CoursesBlock = ({ t, user, courses, setTestingState, testingState }) => {
    if (!user || !user?.counters)
        return;

    if (!courses)
        return;

    const GoToCourse = (id) => {
        if (!testingState.mode)
            return;

        const course = courses.find(({ category_id }) => Number(id) == category_id);
        if (!course)
            return;

        setTestingState({
            ...testingState,
            category: course.category_id,
        });
    };

    const CourseCard = (course) => {
        const userCountersForCourse = user?.counters?.categories?.find(({ categoryid }) => categoryid == course.category_id) ?? null;

        return <div key={course.category_id} className='tp_block_courses_selected' onClick={(e) => GoToCourse(course.category_id)}>
            <img src={course.icons} className='tp_block_courses_pic' />
            <div className='tp_block_sidebar_selected_name'>{t('quiz_card_section')} {course['name_' + user.language]}
                <div className='tp_block_sidebar_selected_description'>
                    {t('quiz_course_card_desc')}
                </div>
            </div>
            <div className='tp_block_sidebar_selected_tasks'>{t('quiz_tasks_completed_card')} <b>{userCountersForCourse ? Number(userCountersForCourse.total_passed) : 0} / {course.total}</b></div>
        </div>;
    };

    return <div className={`tp_block_courses ${testingState.mode == null ? 'disabled' : ''}`}>
        <div className='tp_block_stats'>
            <div className='tp_block_stats_active'>{t('quiz_tab_active_tests')} <b>({courses.length})</b></div>
            <div className='tp_block_stats_active'>{t('quiz_tab_passed_tests')} <b>({user?.counters.total_passed ?? 0})</b></div>
        </div>

        <hr className='tp_block_hr' />

        <div className='tp_block_courses_menu'>
            {courses.map(CourseCard)}
        </div>
    </div>;
};

const TestingPage = observer(() => {
    const { t } = useTranslation();
    const { user } = useContext(Context);
    const navigate = useNavigate();
    const [categories, setCategories] = useState(null);
    const [testingState, setTestingState] = useState({
        category: null,
        mode: null,
        currentquestion: null,
        timestart: null,
        timeend: null,
        answers: [],
        testfinished: false,
    });
    const [questions, setQuestions] = useState([]);

    //  При загрузке страницы впервые нужно получить доступные категории тестирования.
    useEffect(() => {
        fetchTesting_Categories().then((c) => {
            fetchTesting_Counters().then((cc) => {
                c.map((category) => {
                    category.total = cc.categories.find(({ categoryid }) => categoryid == category.category_id)?.total ?? 0;
                    return category;
                });

                setCategories(c);
            });
        });

        fetchTesting_UserCounters(user.profile.id).then((c) => {
            user.profile.createdAgo = c.user.created;
            user.profile.updatedAgo = c.user.updated;

            user.setCounters({
                total_passed: c.testing != undefined ? c.testing.reduce((prev, curr) => prev += Number(curr.total_passed), 0) : 0,
                categories: c.testing,
            });
        });
    }, []);

    //  При изменении чего-то в объекте с информацией о статусе прохождения теста, нужно либо:
    //  загрузить вопросы для выбранной категории и режима,
    //  отправить результаты теста.
    useEffect(() => {
        if (testingState.category && !questions.length)
            fetchTesting_Category_Tests(testingState.category, testingState.mode == 'testing' ? QuestionsForTestingMode : QuestionsForTrainingMode).then((q) => {
                setQuestions(q);
                setTestingState({ ...testingState, currentquestion: 0, timestart: new Date(), answers: q.map(({ id, question }, index) => { return { id: index, qid: id, text: question, selected: [] }; }) });
            });

        if (testingState.testfinished && testingState.answers.length == questions.length && testingState.mode == 'testing')
            recordUserQuiz({ ...testingState, userid: user.profile.id, quizid: testingState.answers.map(({ qid }) => qid) }).then();
    }, [testingState]);

    const PreviousQuestion = (event) => {
        const newQuestionIndex = testingState.currentquestion == 0 ? 0 : testingState.currentquestion - 1;

        setTestingState({
            ...testingState,
            currentquestion: newQuestionIndex,
        });
    };

    const NextQuestion = (event) => {
        let newQuestionIndex = testingState.currentquestion;

        if (questions[testingState.currentquestion + 1] !== undefined)
            newQuestionIndex += 1;

        setTestingState({
            ...testingState,
            currentquestion: newQuestionIndex,
        });
    };

    const UpdateTestingProgress = (answer) => {
        let newQuestionIndex = testingState.currentquestion;

        if (testingState.mode == 'testing') {
            if (questions[testingState.currentquestion + 1] !== undefined)
                newQuestionIndex += 1;
        }

        const updatedAnswers = testingState.answers.map((a, i) => {
            if (a.id == testingState.currentquestion)
                return {
                    ...a,
                    selected: answer,
                };
            else
                return a;
        });

        setTestingState({
            ...testingState,
            currentquestion: newQuestionIndex,
            answers: updatedAnswers,
        });
    };

    const QuestionResultRow = ({ answer }) => {
        const answersGiven = answer.selected.length ? answer.selected.map((a) => a?.text[user.language] ?? t('quiz_no_answer')) : [t('quiz_no_answer')];
        const answersCorrectness = answer.selected.length ? answer.selected.map((a) => a?.correct ? t('quiz_correct_short') : t('quiz_incorrect_short')) : [t('quiz_incorrect_short')];

        return <tr>
            <td>{answer.text[user.language]}</td>
            <td>{answersGiven.map((a, i) => <span key={i}>{a}&nbsp;&ndash;&nbsp;{answersCorrectness[i]},&nbsp;</span>)}</td>
        </tr>;
    };

    //  Вывод информации о результатах тестирования, после его завершения.
    //  Здесь подсчитывается количество верных ответов, а также итоговая оценка.
    const QuizResultOverall = ({ answers }) => {
        let correctAnswers = 0;

        answers.map((a, i) => {
            const thisQuestionCorrectAnswers = questions.find(({ id }) => id == a.qid).correct;
            const correct = a.selected.reduce((prev, curr, i) => prev += curr.correct ? 1 : 0, 0);

            correctAnswers += correct == thisQuestionCorrectAnswers.length ? 1 : 0;
        });

        const percentCorrect = ((correctAnswers / questions.length) * 100);
        let resultMark = 0;

        //  Оценка, на основании процента правильных ответов.
        if (percentCorrect <= 70)
            resultMark = 2;

        if (percentCorrect > 70 && percentCorrect <= 79)
            resultMark = 3;

        if (percentCorrect > 80 && percentCorrect <= 89)
            resultMark = 4;

        if (percentCorrect >= 90)
            resultMark = 5;

        //  В случае, если в режиме "тест" вышло время.
        if (!answers.length)
            return <>
                <div className='text-center-grid'>
                    <div className='text-center'>{t('quiz_title_failed_finish')}</div>
                    <span className='text-body-secondary'>{t('quiz_title_failed_desc')}</span>
                    <button className='btn-primary1' onClick={event => { window.scrollTo(0, 0); window.location.reload(); }}>{t('quiz_finish')}</button>
                </div>
            </>;

        // Если был дан ответ на все вопросы.
        return <>
            <div className='text-center-grid'>
                <div className='text-center'>{t('quiz_title_finished')}</div>
                <div className='text-grid'>
                    <div className='text-small'>
                        {t('quiz_answer_correct_list')}&nbsp;
                        <div className='text-small-num'>{correctAnswers}&nbsp;/&nbsp;{answers.length}</div>
                    </div>

                    <div className='text-small'>
                        {t('quiz_mark')}:&nbsp;<br></br>
                        <div className='text-small-num'>{resultMark}</div>
                    </div>
                </div>
                <button className='btn-primary1' onClick={event => { window.scrollTo(0, 0); window.location.reload(); }}>{t('quiz_finish')}</button>
                <span className='text-body-secondary fs-6'>{t('quiz_hint_results')}</span>
            </div>
        </>;
    };

    const SetCurrentQuestion = (questionId) => {
        setTestingState({
            ...testingState,
            currentquestion: questionId,
        });
    };

    //  Будет вызвано при истечении времени выделенного на тестирование.
    const HandleTimerEnd = () => {
        alert(`Время вышло. Тестирование завершено.`);

        //  Если были даны ответы на все вопросы - то рассчитать оценку. Если иначе - то тест не засчитан.
        let correctAnswers = 0, answersTotal = 0;

        testingState.answers.map((a, i) => {
            const thisQuestionCorrectAnswers = questions.find(({ id }) => id == a.qid).correct;
            let correct = 0;

            if (a.selected.length) {
                correct = a.selected.reduce((prev, curr, i) => prev += curr.correct ? 1 : 0, 0);
                answersTotal++;
            }

            correctAnswers += correct == thisQuestionCorrectAnswers.length ? 1 : 0;
        });

        //  В случае, если кол-во ответов не совпадает с кол-вом вопросов, то обнулить ответы, чтобы тест был не засчитан.
        setTestingState({
            ...testingState,
            answers: answersTotal != questions.length ? [] : testingState.answers,
            timeend: new Date(),
            testfinished: true,
        });
    };

    //  Прервать тестирование, не завершив его.
    const HandleTerminateTesting = () => {
        if (!window.confirm(`Вы уверены, что хотите прервать тестирование?`))
            return;

        setQuestions([]);
        setTestingState({ category: null, mode: null, currentquestion: null, timestart: null, timeend: null, answers: [] });
        window.scrollTo(0, 0);
    };

    //  Завершить тестирование возможно лишь ответив на все вопросы.
    const HandleFinishTesting = () => {
        const questionsAnswered = testingState.answers.reduce((prev, curr) => prev += (curr.selected.length != 0 ? 1 : 0), 0);

        if (testingState.mode == 'testing' && questionsAnswered != questions.length) {
            //  Тестирование было пройдено не полностью - есть вопросы без ответов.
            alert(`Вы ответили на ${questionsAnswered} из ${questions.length} вопросов. Завершить тестирование возможно лишь ответив на все вопросы.`);
        }
        else {
            //  Тестирование можно завершить.
            setTestingState({
                ...testingState,
                testfinished: true,
            });
        }
    };

    if (!user || !user?.profile?.id)
        return navigate('/');

    //  Для пользователя с демо доступом - уйти со страницы.
    const isDemoUser = user.profile.access_level.demo_access;
    if (isDemoUser)
        return navigate('/');

    return <div className='testing_page'>
        <div className={`testing_page_grid ${testingState.category ? 'wide' : ''}`}>

            {!testingState.category && <>
                <TestingTypeBlock t={t} setTestingState={setTestingState} testingState={testingState} />
                <UserInfoBlock t={t} user={user} />
                <CoursesBlock t={t} user={user} courses={categories} setTestingState={setTestingState} testingState={testingState} />
                <AchievementsInfoBlock t={t} />
            </>}

            {
                !testingState.testfinished && questions.length > 0 && <div className='questions_wrapper'>
                    <div className='questions'>
                        {questions.length > 0 && <>

                            <QuizComponent key={testingState.currentquestion} testData={questions?.at(testingState.currentquestion)} showCorrect={testingState.mode == 'training'} onAnswer={UpdateTestingProgress} />
                            <div className='controls'>
                                <button disabled={testingState.currentquestion == 0} onClick={PreviousQuestion}>{t('quiz_previous')}</button>
                                <button onClick={HandleTerminateTesting}>{t('quiz_terminate')}</button>
                                <button onClick={NextQuestion}>{t('quiz_next')}</button>
                            </div>
                        </>}
                    </div>
                    <div className='test_progress'>
                        {/* <div className='test_progress_name'>{t('quiz_answers')}</div> */}
                        <div className='question_number'>
                            {t('quiz_question')} {testingState.currentquestion + 1} / {questions.length}
                        </div>
                        <div className='header'>
                            {testingState.mode == 'testing' && <div className='time_left'><CountdownTimer time={TimeLimitForTestingMode} onEnd={HandleTimerEnd} /></div>}
                        </div>
                        <div className='answers_wrapper'>
                            {questions.map((q, qid) => {
                                let answerStyleClass = 'answer';
                                if (testingState.answers.find(({ qid }) => qid == q.id)?.selected.length > 0)
                                    answerStyleClass += ' answered';
                                if (testingState.currentquestion == qid)
                                    answerStyleClass += ' disabled';

                                return <div className={answerStyleClass} key={q.id} onClick={() => SetCurrentQuestion(qid)}>{qid + 1}</div>;
                            })}
                        </div>
                        <button className='btn_test_end' onClick={HandleFinishTesting}>{t('quiz_finish')}</button>
                    </div>
                </div>
            }

            {
                testingState.testfinished && <div className='results'>
                    <div className='overall'>
                        <QuizResultOverall answers={testingState.answers} />
                    </div>
                    {
                        /*
                        <table className='table table-striped'>
                        <thead>
                            <tr className='table-secondary'>
                                <th>{t('quiz_question')}</th>
                                <th>{t('quiz_answer')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {testingState.answers.map((a, i) => <QuestionResultRow key={i} answer={a} />)}
                        </tbody>
                        </table>
                        */
                    }
                </div>
            }</div>
    </div >;
});

export default TestingPage;