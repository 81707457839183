import { observer } from "mobx-react-lite";
import Ultrasound_Data_EntriesItem from "./Ultrasound_Data_EntriesItem";

const Ultrasound_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='ecg_list_flex'>
        {pageData.items.rows.map((item) => <Ultrasound_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Ultrasound_Data_EntriesList;